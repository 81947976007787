import React, { useContext, useState, useRef, useEffect } from 'react'
import { Prompt } from 'react-router'
import { useHistory } from 'react-router-dom'
import Alert from '../common/Alert'
import { APIContext } from '../../utils/api'
import { GlobalContext } from '../../utils/globalState'
import { easingFunctions } from '../../utils/easingFunctions'
import { colors } from '../../themes/colors'
import checkMark from '../../assets/images/checkmark.svg'
import iconUserAdd from '../../assets/images/icon-user-add.svg'
import iconUserDelete from '../../assets/images/icon-user-delete.svg'

// styles
const seatsBoxLeftStyle = {
	flexBasis: '0px',
	flexGrow: '2',
	paddingRight: '47px',
	boxSizing: 'border-box',
	textAlign: 'left'
}

const seatsBoxRightStyle = {
	flexBasis: '0px',
	flexGrow: '3',
	margin: '30px 0 0'
}

const accountElementBack = {
	padding: '53px 50px 70px',
	borderTop: '1px solid ' + colors.borderLight,
	minWidth: '980px'
}

const seatsBoxStyle = {
	display: 'flex',
	flexWrap: 'wrap',
	backgroundColor: colors.background2,
	margin: '27px 0 20px',
	padding: '0 9px 10px 30px',
	color: colors.text
}

const labelStyle = {
	fontSize: '12px',
	lineHeight: '16px',
	fontFamily: 'Greycliff demibold',
	color: colors.textLabel,
	whiteSpace: 'nowrap'
}

const checkBoxStyle = {
	backgroundColor: colors.background0,
	margin: '6px 15px 0 0',
	borderRadius: '50%',
	height: '28px',
	width: '28px',
	backgroundPosition: '50% 50%',
	backgroundRepeat: 'no-repeat'
}

// component function
export default function AccountSeats(props) {

	const {org} = props
	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const history = useHistory()
	const [alertTask, setAlertTask] = useState()
	const [users, setUsers] = useState()
	const [submitting, setSubmitting] = useState(false)
	const alertObjectRef = useRef(null)
	const maxSeats = org.subscriptionPlan?.config.User_seats
	const rolesAdmin = [1,1000]
	const rolesUser = [10,1100]
	const guidAdmin = '92ba4e65-2ea5-4b2a-a3bf-696cec1bca2b'
	const guidUser = '578309d7-0833-442a-9aa7-70987a2da976'

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// get user list
	useEffect(() => {
		getTeam()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.io.socket, globalState.userData, maxSeats])

	// get team
	function getTeam() {
		context.io.socket.get('/api/v1/organization/user', (data, res) => {
			if (res.statusCode === 200) {
				const meIdx = globalState.userData && data.findIndex(user => user.email === globalState.userData.email)
				const me = data[meIdx]
				data.splice(meIdx,1)
				data.unshift(me) // move my user to top of list
				data.length < maxSeats && data.push({name:'', email:'', userrole:rolesUser[1]}) // add empty seat if any vacant
				setUsers(data)
			} else {
				showAlert('Error getting user list: ' + res.error.message)
			}
		})
	}

	// validate email
	function validateEmail(email) {
		const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i //eslint-disable-line
		return re.test(String(email).toLowerCase())
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// scroll to top
	function goTop(duration, offset) {
		const start = window.pageYOffset
		const time = Date.now();
		(function step() {
			let dx = Math.min(1, (Date.now() - time) / duration)
			let pos = start + (offset - start) * easingFunctions.easeInOutQuad(dx)
			window.scrollTo(0, pos)
			dx < 1 && requestAnimationFrame(step)
		})()
	}

	// change users array based on new users input fields
	function userChange(inp) {
		const u = [...users]
		const field = inp.id.split('-')
		const idx = field[1]
		if (field[0] === 'name') {
			u[idx].name = inp.value
		}
		if (field[0] === 'email') {
			u[idx].email = inp.value
		}
		setUsers(u)
	}

	// toggle admin checkmark
	function toggleCheckmark(id) {
		const userToChange = users[id.split('-')[1]]
		if (rolesAdmin.indexOf(userToChange.userrole) > -1 && users.filter(user => rolesAdmin.indexOf(user.userrole) > -1).length < 2) { // admin -> non-admin - alert if no admins left
			showAlert('Can’t remove Manager permissions from ' + userToChange.name + '. There must be at least one Team manager.')
		} else if (rolesAdmin.indexOf(userToChange.userrole) > -1 && globalState.userData?.email === userToChange.email) { // removing current user as admin?
			alertObjectRef.current = {
				type:'confirm',
				title:'WARNING!\nIf you remove Manager permissions from your own user you will LOSE ACCESS TO THIS PAGE!',
				message:'You will be redirected away from this page.\nIf you want to regain access you must ask another team manager to grant you Manager permission.',
				cancelLabel: 'Keep Manager',
				actionLabel: 'Remove Manager'
			}
			setAlertTask(()=>(action)=>{ // define alert action and display alert
				action && setUserRole(userToChange)
				setAlertTask() // remove alert
			})
		} else {
			if (!userToChange.id) { // new user - don’t send role to backend
				userToChange.userrole = rolesAdmin.indexOf(userToChange.userrole) > -1 ? rolesUser[1] : rolesAdmin[1]
				setUsers([...users])
			} else {
				setUserRole(userToChange)
			}
		}
	}

	// set user role
	function setUserRole(user) {
		if (submitting) return
		setSubmitting(true)
		const guidRole = rolesAdmin.indexOf(user.userrole) > -1 ? guidUser : guidAdmin
		context.io.socket.patch('/api/v1/organization/user/' + user.guid, { role:guidRole }, (data, res) => {
			if (res.statusCode === 200) {
				if (globalState.userData?.email === user.email) { // revoked admin from own user - update globalState and redirect to previous page
					setGlobalState({...globalState, userData:{...globalState.userData, userrole:data.userrole}})
					history.goBack()
				} else {
					user.userrole = data.userrole
					setUsers([...users])
				}
			} else {
				showAlert('Error setting user permissions: ' + res.error.message)
			}
			setSubmitting(false)
		})
	}

	// add user
	function addUser(id) {
		if (submitting) return
		const userToAdd = users[id.split('-')[1]]
		// validate
		if (userToAdd.name.trim().length > 0 && !userToAdd.email.trim().length > 0) {
			showAlert(userToAdd.name + ' is missing an email address')
		} else if (userToAdd.name.length > 0 && userToAdd.name.trim().length === 0) {
			showAlert('Name can’t just be spaces')
		} else if (userToAdd.name.trim().length > 0 && userToAdd.email.trim().length > 0 && !validateEmail(userToAdd.email)) {
			showAlert(userToAdd.name + ' has an error in the email address')
		} else if (userToAdd.email.trim().length > 0 && !validateEmail(userToAdd.email)) {
			showAlert('There is an an error in an email address and no name in the Name field')
		} else if (userToAdd.email.trim().length > 0 && userToAdd.name.trim().length === 0) {
			showAlert('The user with email address ' + userToAdd.email + ' is missing a name')
		} else if (!userToAdd.name && !userToAdd.email && rolesAdmin.indexOf(userToAdd.userrole) > -1) {
			showAlert('A new user is assigned Manager permissions but is missing name and email')
		} else if (userToAdd.email.trim().length > 0 && users.filter(user => user.email === userToAdd.email).length > 1) {
			showAlert('The email address “' + userToAdd.email + '” is already used by another team member')
		} else if (userToAdd.name.trim().length > 0 && userToAdd.email.trim().length > 0) { // submit
			setSubmitting(true)
			const guidRole = rolesAdmin.indexOf(userToAdd.userrole) > -1 ? guidAdmin : guidUser
			context.io.socket.post('/api/v1/organization/user', { email:userToAdd.email, name:userToAdd.name, role:guidRole }, (data, res) => {
				if (res.statusCode === 200) {
					showAlert(userToAdd.name + ' was added to the team')
					getTeam()
				} else if (res.statusCode === 409) {
					showAlert('Email address is already in use by another account')
				} else {
					showAlert('Error creating user: ' + res.error.message)
				}
				setSubmitting(false)
			})
		}
	}

	// delete user
	function deleteUser(id) {
		if (submitting) return
		const userToDelete = users[id.split('-')[1]]
		if (rolesAdmin.indexOf(userToDelete.userrole) > -1 && users.filter(user => rolesAdmin.indexOf(user.userrole) > -1).length < 2) { // alert if trying to delete last admin
			showAlert('Can’t remove ' + userToDelete.name + ' from the team.\nThere must be at least one Team manager.')
		} else {
			const nameEnd = userToDelete.name.slice(-1).toLowerCase() === 's' ? '’' : '’s'
			alertObjectRef.current = {
				type:'confirm',
				title:'Remove ' + userToDelete.name + ' from the team?',
				message:'Analyses made by ' + userToDelete.name + ' will be transferred to YOUR account and ' + userToDelete.name + nameEnd + ' flowsam account will be deleted.',
				cancelLabel: 'Don’t remove',
				actionLabel: 'Remove & transfer'
			}
			setAlertTask(()=>(action)=>{ // define alert action and display alert
				if (action) {
					setSubmitting(true)
					context.io.socket.delete('/api/v1/organization/user/' + userToDelete.guid, (data, res) => {
						if (res.statusCode === 200) {
							getTeam()
						} else {
							showAlert('Error creating user: ' + res.error.message)
						}
						setSubmitting(false)
					})
				}
				setAlertTask() // remove alert
			})
		}
	}

	const userList = users?.map((user, i) => {

		const inputFields = (user.id ?
			<>
				<div style={{width:'270px',marginRight:'20px'}}><input id={'seat-'+i+'-user'} type="text" style={{cursor:'default'}} className={'fs-input-text-signup'} value={user.name} readOnly title="User name must be edited from the user’s own account" /></div>
				<div style={{width:'270px',marginRight:'20px'}}><input id={'seat-'+i+'-email'}  type="email" style={{cursor:'default'}} className={'fs-input-text-signup'} value={user.email} readOnly title="Email must be edited from the user’s own account" /></div>
			</> :
			<>
				<div style={{width:'270px',marginRight:'20px'}}><input id={'name-'+i} type="text" className={'fs-input-text-signup'} placeholder="Name" spellCheck="false" autoComplete="off" maxLength="50" value={user.name} onChange={e=>userChange(e.target)} /></div>
				<div style={{width:'270px',marginRight:'20px'}}><input id={'email-'+i} type="email" className={'fs-input-text-signup'} placeholder="Email" spellCheck="false" autoComplete="off" maxLength="100" value={user.email} onChange={e=>userChange(e.target)} /></div>
			</>
		)

		const adminCheck = <div id={'checkmark-'+i} style={{...checkBoxStyle, backgroundImage: (user && rolesAdmin.indexOf(user.userrole) > -1 ? 'url(' + checkMark + ')' : 'none'), cursor:'pointer'}} onClick={e=>toggleCheckmark(e.target.id)} title={user && rolesAdmin.indexOf(user.userrole) > -1 ? 'Revoke Manager permissions' : 'Grant Manager permissions'} />

		const actionButton = (!user.id ?
			<div id={'add-'+i} style={{...checkBoxStyle, backgroundImage: 'url(' + iconUserAdd + ')', cursor:(users[i].name || users[i].email || rolesAdmin.indexOf(users[i].userrole) > -1) ? 'pointer' : 'default', opacity:(users[i].name || users[i].email || rolesAdmin.indexOf(users[i].userrole) > -1) ? '1' : '.6'}} onClick={e=>addUser(e.target.id)} title="Add user to team" /> :
			globalState.userData?.email !== user.email ?
			<div id={'delete-'+i} style={{...checkBoxStyle, backgroundImage: 'url(' + iconUserDelete + ')', cursor:'pointer'}} onClick={e=>deleteUser(e.target.id)} title="Remove user from team" /> :
 			<div style={{...checkBoxStyle, opacity:'0'}} />
		)

		return (
			<div key={i} style={{display:'flex'}}>
				{inputFields}
				{adminCheck}
				{actionButton}
			</div>
		)
	})

	const newMembersTxt = maxSeats > 1 && <h4 style={{marginBottom: '14px'}}><span style={{fontFamily:'Greycliff demibold'}}>New team members</span> will get their own flowsam login and receive a welcome mail with a temporary password.</h4>
	const moreSeatsTxt = users?.filter(user => user.id).length === maxSeats && <h4 style={{marginBottom: '14px', color:colors.link, cursor:'pointer'}} onClick={e=>goTop(600,35)}>Upgrade your flowsam plan to get more user seats.</h4>

	return (
		<div style={accountElementBack}>
			<div className="accountElementContainer">
				<h2 style={{color:colors.text}}>User seats</h2>
				<div style={seatsBoxStyle}>
					<div style={seatsBoxLeftStyle}>
						<h4 style={{margin: '21px 0 14px'}}><span style={{fontFamily:'Greycliff demibold'}}>Team managers</span> have permission to edit all sections of this page.</h4>
						{newMembersTxt}
						{moreSeatsTxt}
					</div>
					<div style={seatsBoxRightStyle}>
						<div style={{...labelStyle, textAlign:'right', margin:'-20px 45px 4px 0'}}>Manager</div>
						{userList}
					</div>
				</div>
			</div>
			<Prompt when={users?.filter(user => !user.id && (user.name || user.email || rolesAdmin.indexOf(user.userrole) > -1)).length > 0} message="If you leave now your new user will not be created" />
			{alert}
		</div>
	)
}
