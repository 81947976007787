import React, { useState, useRef } from 'react'
import Alert from '../common/Alert'
import AccountSubscriptionPlans  from './AccountSubscriptionPlans'
import { colors } from '../../themes/colors'

// styles
const subColumnsContainerStyle = {
	display: 'flex',
	alignItems: 'stretch',
	margin: '27px -12px 40px'
}

const subColumnHeaderStyle = {
	display: 'flex',
	alignItems: 'center',
	height: '83px',
	boxSizing: 'border-box',
	backgroundColor: colors.background3,
	color: colors.textInverse,
	padding: '0 30px'
}

const subColumnTxtStyle = {
	padding: '15px 30px 18px',
	fontSize: '18px',
	lineHeight: '39px',
	textAlign: 'left'
}

const boldHeaderStyle = {
	fontFamily: 'Greycliff demibold',
	lineHeight: '24px',
	textAlign: 'center'
}

const accountElementBack = {
	padding: '53px 50px 70px',
	minWidth: '980px'
}

const subColumnStyle = {
	margin: '0 12px',
	backgroundColor: colors.background2,
	flexGrow: '1'
}

const labelStyle = {
	fontSize: '12px',
	lineHeight: '16px',
	fontFamily: 'Greycliff demibold',
	color: colors.textLabel,
	whiteSpace: 'nowrap'
}

const subDataStyle = {
	marginBottom: '12px',
	color: colors.text,
	whiteSpace: 'nowrap'
}

// component function
export default function AccountSubscription(props) {

	const {org, plans} = props

	const [alertTask, setAlertTask] = useState()
	const [showPlans, setShowPlans] = useState(false)
	const alertObjectRef = useRef(null)
	const dateOptions = { year:'numeric', month:'short', day:'numeric' }

	const plan = org.subscriptionPlan
	const calculations = org.subscriptionCalculations

	const subName = plan && plan.name + (plan.id !== 9 ? plan.config.Monthly_billing ? ' - monthly' : ' - yearly' : '') // not trial - append cycle
	const subStart = calculations && new Date(calculations.subStart).toLocaleDateString("en-GB", dateOptions)
	const subRenew = calculations && (calculations.subCancel !== 0 || calculations.subExpired) ? 'n/a' : new Date(calculations.subEnd).toLocaleDateString("en-GB", dateOptions)
	const subRefill = calculations && (calculations.subCancel !== 0 || calculations.subExpired) ? 'n/a' :  new Date(calculations.currentPeriodEnd).toLocaleDateString("en-GB", dateOptions)
	const subExpiration = calculations && (calculations.subCancel !== 0 || calculations.subExpired) && calculations.subEnd > 0 ? new Date(calculations.subEnd).toLocaleDateString("en-GB", dateOptions) : 'n/a'
	const subStatus = calculations ? calculations.subExpired ? 'Expired' : calculations.subCancel !== 0 ? 'Canceled' : 'Active' : ''
	const analysesLeft = plan && (plan.config.Total_videos_month - calculations.currentPeriodAnalysesUsed)
	const extraAnalysesLeft = org.freeAnalyses ? org.freeAnalyses : 0
	const unusedUserSeats = calculations?.unusedUserSeats
	const country = org.companyStoreData?.customer?.address?.country
	const taxFactor = country === 'DK' ? .25 : 0
	const priceInclVat = plan ? plan.config.Monthly_billing ? numberWithCommas(plan.config.Monthly_ex_VAT_Monthly_billing * (1+taxFactor)) : numberWithCommas(plan.config.Monthly_ex_VAT_Annual_billing * (1+taxFactor)) : ''

	const plansButtonTxt = calculations?.subCancel !== 0 || calculations?.subExpired ? 'SELECT A PLAN' : 'CHANGE PLAN'

	const cycleName = plan?.config.Calc_cycle_length_month === 12 ? 'year' : 'month'

	const priceExclVat = plan ? plan.config.Monthly_billing ? numberWithCommas(plan.config.Monthly_ex_VAT_Monthly_billing) : numberWithCommas(plan.config.Monthly_ex_VAT_Annual_billing) : ''
	const totalVideosPerCycle = plan?.config.Total_videos_month
	const totalUserSeats = plan?.config.User_seats
	const videoMaxSecs = plan?.config.Max_film_length
	const videoMaxMb = plan?.config.Max_Mb_per_film

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// dynamic styles
	const plansContainerStyle = {
		maxHeight: showPlans ? '1230px' : '0',
		overflow: 'hidden',
		transition: 'max-height 1.8s'
	}

	const plansButtonStyle = {
		transition: 'background-color .2s, opacity .4s, margin-bottom .6s',
		opacity: showPlans ? '0' : '1',
		cursor:	showPlans ? 'auto' : 'pointer'
	}

	// add thousand separators to number
	function numberWithCommas(val) {
	    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

	function showSubscriptionPlans() {
		if (plan.id > 9) { // custom plan - don’t show 'change subscription' button
			alertObjectRef.current = { title:'You are on an custom subscription plan', message:'If you want changes to your plan please contact your flowsam sales representative.' }
			setAlertTask(()=>(action)=>{setAlertTask()})
		} else {
			setShowPlans(true)
		}
	}

	return (
		<>
			<div style={{...accountElementBack, paddingTop:'53px'}}>
				<div className="accountElementContainer">
					<h2 style={{color:colors.text}}>Flowsam subscription</h2>
					<div style={subColumnsContainerStyle}>
						<div style={{...subColumnStyle, flexBasis:'570px'}}>
							<div style={subColumnHeaderStyle}>
								<h2 style={{lineHeight:'32px', textAlign:'left', color:colors.textInverse}}>{subName}</h2>
							</div>
							<div style={{...subColumnTxtStyle, padding:'24px 30px 26px', lineHeight:'22px', display:'flex', justifyContent:'space-between'}}>
								<div style={{marginRight:'30px', marginBottom:'-12px'}}>
									<div style={labelStyle}>Subscription start</div>
									<div style={subDataStyle}>{subStart}</div>
									<div style={labelStyle}>Subscription renewal</div>
									<div style={subDataStyle}>{subRenew}</div>
									<div style={labelStyle}>Analysis count refill</div>
									<div style={subDataStyle}>{subRefill}</div>
								</div>
								<div style={{marginRight:'30px', marginBottom:'-12px'}}>
									<div style={labelStyle}>Status</div>
									<div style={subDataStyle}>{subStatus}</div>
									<div style={labelStyle}>Subscription expiration</div>
									<div style={subDataStyle}>{subExpiration}</div>
									<div style={labelStyle}>Next payment{calculations?.subCancel === 0 && !calculations?.subExpired && ' (' + priceInclVat + '€)'}</div>
									<div style={subDataStyle}>{subRefill}</div>
								</div>
								<div style={{marginRight:'30px', marginBottom:'-12px'}}>
									<div style={labelStyle}>Vacant user seats</div>
									<div style={subDataStyle}>{unusedUserSeats}</div>
									<div style={labelStyle}>Analyses left</div>
									<div style={subDataStyle}>{analysesLeft}</div>
									<div style={labelStyle}>Extra analyses left</div>
									<div style={subDataStyle}>{extraAnalysesLeft}</div>
								</div>
							</div>
						</div>
						<div style={{...subColumnStyle, flexBasis:'290px'}}>
							<div style={subColumnHeaderStyle}>
								<h3 style={{...boldHeaderStyle, textAlign:'left', lineHeight:'22px', marginTop:'4px', padding:'0'}}>Price/{cycleName} <span style={{fontSize:'18px'}}>(ex.&nbsp;VAT)</span></h3>
							</div>
							<div style={{...subColumnTxtStyle, whiteSpace:'nowrap', color:colors.text}}>
								Video analyses/{cycleName}<br/>
								User seats<br/>
								Max. video length<br/>
								Max. file size per video
							</div>
						</div>
						<div style={{...subColumnStyle, flexBasis:'170px'}}>
							<div style={{...subColumnHeaderStyle, justifyContent:'center'}}>
								<h2 style={{wordSpacing:'-.1em', padding:'0', color:colors.textInverse}}>{priceExclVat}&nbsp;€</h2>
							</div>
							<div style={{...subColumnTxtStyle, textAlign:'center', color:colors.text}}>
								{totalVideosPerCycle}<br/>
								{totalUserSeats}<br/>
								{videoMaxSecs} secs<br/>
								{videoMaxMb} MB
							</div>
						</div>
					</div>
					<button tabIndex="-1" className="fs-button accountSubmitBtn" style={plansButtonStyle} onClick={showSubscriptionPlans}>{plansButtonTxt}</button>
					<div style={plansContainerStyle}>
						<AccountSubscriptionPlans org={org} plans={plans} />
					</div>
				</div>
			</div>
			{alert}
		</>
	)
}
