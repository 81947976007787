import React from 'react'
import { industryOptions } from '../../../utils/industryOptions'
import flowsamLogo from '../../../assets/images/flowsam-logo.svg'
import socialVideoIcon from '../../../assets/images/media-type-icons/icon-social-media-video-white.svg'
import styles from'./styles.module.css'

// component function
export default function ReportHeader(props) {

	const { video } = props

	// find label for video industry code
	const industry = video?.industryCode !== undefined && industryOptions.find(ind => ind.value === video.industryCode)
	const industryLabel = industry?.label

	// analysis creation date
	const dateOptions = { year:'numeric', month:'short', day:'numeric', hour:'numeric', minute:'numeric' }
	const date = video?.createdAt ? new Date(video.createdAt).toLocaleDateString("en-GB", dateOptions) : null

	// video duration
	const duration = (video.analysisType !== 3 && // not still image
		<div className={styles.detail}>
			<div className={styles.detailLabel}>Duration</div>
			<div className={styles.detailText}>{Math.round(video.frameCount/video.frameRate)} seconds</div>
		</div>
	)

	return (
		<div className={styles.background}>
			<div className={styles.header}>
			<div className={styles.heading}>Ad performance report</div>
				<img src={flowsamLogo} className={styles.logo} alt="Flowsam logo" />
			</div>
			<div className={styles.detailsWrapper}>
				<div className={styles.icon}>
					<img src={socialVideoIcon} alt="" /> {/* TODO: base icon on media type */}
				</div>
				<div className={styles.detailsGroupsWrapper}>
					<div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Brand</div>
							<div className={styles.detailText}>{video.brand?.name}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Ad name</div>
							<div className={styles.detailText}>{video.title}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Campaign</div>
							<div className={styles.detailText}>{video.project?.name}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Industry</div>
							<div className={styles.detailText}>{industryLabel}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Version</div>
							<div className={styles.detailText}>[3A]</div> {/* TODO: get version */}
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Test date</div>
							<div className={styles.detailText}>{date}</div>
						</div>
					</div>
					<div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Media type</div>
							<div className={styles.detailText}>[Online video]</div> {/* TODO: get media type */}
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Format</div>
							<div className={styles.detailText}>[16:9]</div> {/* TODO: get format */}
						</div>
						{duration}
						<div className={styles.detail}>
							<div className={styles.detailLabel}>File name</div>
							<div className={styles.detailText}>{video.filename}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Test type</div>
							<div className={styles.detailText}>[Own ad]</div> {/* TODO: get test type */}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
