import React, { useState, useEffect, useRef } from 'react'
import { colors } from '../../themes/colors'

// styles
const buttonStyle = {
	minWidth: '120px',
	padding: '6px 24px 5px',
	fontSize: '14px',
	letterSpacing: '.2px'
}

const messageContainerStyle = {
	width: '500px',
	backgroundColor: colors.background1,
	borderRadius: '15px',
	boxSizing: 'border-box',
	padding: '39px 40px 43px',
	textAlign: 'center',
	boxShadow: '0px 0px 70px 0px ' + colors.alertShadow
}

// component function
export default function AlertTerms(props) {

	const { action } = props
	const [opacity, setOpacity] = useState(0)
	const timeoutID = useRef()

	// dynamic styles
	const overlayStyle = {
		position: 'fixed',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100%',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		opacity: opacity,
		transition: 'opacity .4s',
		backgroundColor: colors.backgroundOverlay,
		zIndex: '9999'
	}

	// fade in & set keypress listener
	useEffect(() => {
		timeoutID.current = setTimeout(function() { // delay - otherwise no opacity transition
			setOpacity(1)
		}, 200)
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// handle button click
	function performAction(e,b) {
		e.nativeEvent.stopImmediatePropagation()
		action(b)
	}

	const titleDiv = <h4 style={{marginBottom:'10px', color:colors.text, fontFamily:'Greycliff demibold'}}>You must accept the Terms & conditions<br/>before you can use flowsam</h4>
	const messageDiv = <div style={{fontSize:'14px',lineHeight:'20px',color:colors.text}}>Please read the <a href="https://flowsam.ai/terms/" style={{color:colors.link,textDecoration:'none'}} target="_blank" rel="noopener noreferrer">Terms & conditions</a> before you accept.</div>
	const buttonCancel = <button style={{...buttonStyle, backgroundColor:colors.cancelButtonBack, marginRight:'20px'}} tabIndex="-1" className="fs-button" onClick={e=>performAction(e,false)}>NO, LOG ME OUT</button>
	const buttonOk = <button style={buttonStyle} tabIndex="-1" className="fs-button" onClick={e=>performAction(e,true)}>YES, I ACCEPT</button>

	return (
		<div id="overlay" style={overlayStyle}>
			<div style={messageContainerStyle}>
				{titleDiv}
				{messageDiv}
				<div style={{marginTop:'30px'}}>{buttonCancel}{buttonOk}</div>
			</div>
		</div>
	)
}
