import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { colors } from '../../themes/colors'

// component function
export default function DropDown(props) {

	const { value, options, onChange, placeholder, searchable, small=false } = props

	// styles
	const customStyles = {
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? colors.inputHighlight : colors.inputBack,
			color: state.isFocused ? colors.text : colors.inputPlaceholder,
			fontSize: small ? '14px' : '18px',
			height: small ? '34px' : '40px',
			padding: small ? '7px 0 0 17px' : '10px 0 0 20px',
			textAlign: 'left',
			'&:active': {
				backgroundColor: colors.inputHighlight
			}
		}),
		control: (provided) => ({
			...provided,
			border: 0,
			borderRadius: 8,
			borderBottom: '1px ' + colors.inputBorder + ' solid',
			backgroundColor: colors.inputBack,
			fontSize: small ? '14px' : '18px',
			height: small ? '38px' : '47px',
			padding: small ? '2px 10px 0 9px' : '1px 10px 0 12px',
			boxShadow: 'none',
			'&:hover': {
				borderBottom: '1px ' + colors.inputBorder + ' solid'
			}
		}),
		input: (provided) => ({
			...provided,
			color: colors.text,
			marginLeft: 0
		}),
		singleValue: (provided) => ({
			...provided,
			color: colors.text,
			marginLeft: 0
		}),
		menu: (provided) => ({
			...provided,
			marginTop: 0,
			borderRadius: 0,
			backgroundColor: colors.inputBack,
			boxShadow: 'none'
		}),
		placeholder: (provided) => ({
			...provided,
			color: colors.inputPlaceholder,
			marginLeft: 0
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: colors.inputPlaceholder,
			display: searchable ? 'none' : 'block',
			padding: small ? '4px 0 0' : '4px 7px 0 0',
		}),
		indicatorSeparator: () => ({
			display: 'none'
		})
	}

	return (
		<CreatableSelect
			value={value}
			styles={customStyles}
			onChange={(selectedOption) => onChange(searchable ? selectedOption.label : selectedOption.value)}
			options={options}
			placeholder={placeholder}
			isSearchable={searchable}
		/>
	)
}
