import React, { useState, useEffect, useContext, useRef } from 'react'
import { Prompt } from 'react-router'
import { useHistory } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { Tour } from 'antd'
import { APIContext } from '../../utils/api'
import { GlobalContext } from '../../utils/globalState'
import { colors } from '../../themes/colors'
import AnalysisCueVideoEasy from './AnalysisCueVideoEasy'
import iconAnalysis from '../../assets/images/icon-analysis.svg'
import keysLeft from '../../assets/images/tag-keys-left-arrow.svg'
import keysSpace from '../../assets/images/tag-keys-space-bar.svg'
import keysRight from '../../assets/images/tag-keys-right-arrow.svg'

// styles
const containerStyle = {
	marginLeft: '70px',
	padding: '70px 20px 80px'
}

const iconStyle = {
	margin: '0 16px -8px 0'
}

const headingStyle = {
	color: colors.text
}

const subHeadingStyle = {
	margin: '15px 0 33px',
	color: colors.text
}

const infoButtonStyle = {
	position: 'relative',
    top: '-3px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	boxSizing: 'border-box',
	width: '18px',
	height: '18px',
	borderRadius: '50%',
	backgroundColor: colors.infoButtonBack,
	color: colors.infoButtonText,
	fontSize: '12px',
	fontFamily: 'Greycliff demibold',
	margin: '0 0 4px 8px',
	padding: '2px 0 0',
	transition: 'color .2s',
	cursor: 'pointer'
}

const loaderWrapStyle = {
	position: 'relative',
	paddingTop: '70px'
}

const loaderStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: 'calc(100vh - 343px)',
	paddingTop: '20px'
}

const sumbitLoaderStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '48px',
	padding: '21px 0 0'
}

const buttonStyle = {
	margin: '20px 15px 0',
	padding: '0',
	width: '160px',
	height: '36px',
	fontSize: '14px',
	letterSpacing: '.5px'
}

const reportButtonStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '120px',
	height: '30px',
	margin: '25px auto -38px',
	fontSize: '12px',
	fontFamily: 'Greycliff demibold',
	color: colors.text,
	opacity: '.5',
	cursor: 'pointer'
}

const videoContainerStyle = {
	width: '1080px',
	margin: '40px auto 0',
}

const loaderPctStyle = {
	position: 'absolute',
	top: 0,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: 'calc(100vh - 343px)',
	paddingTop: '52px',
	color: colors.text
}

const submitLoaderPctStyle = {
	width: '100%',
	height: '20px',
	marginBottom: '-20px',
	color: colors.text
}

// component function
export default function AnalysisCues(props) {

	const { title, guid } = props.data
	const context = useContext(APIContext)
	const history = useHistory()
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const [video, setVideo] = useState()
	const [forceUpdate, setForceUpdate] = useState(0)
	const [chunksLoaded, setChunksLoaded] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [saving, setSaving] = useState(false)
	const [finishing, setFinishing] = useState(false)
	const [submitProgress, setSubmitProgress] = useState(0)
	const chunkArrayRef = useRef([])
	const origSegmentRef = useRef([])
	const objectsToUpdateRef = useRef([])
	const segmentsToAddRef = useRef([])
	const objectsToAddRef = useRef([])
	const objectIdsToDeleteRef = useRef([])
	const segmentIdsToDeleteRef = useRef([])
	const videoRef = useRef()
	const savedRef = useRef(false)
	const maxWidth = 1080

	let numChunksLoaded = 0

	// Tour
	const ref1 = useRef(null)
	const ref2 = useRef(null)
	const ref3 = useRef(null)
	const ref4 = useRef(null)
	const ref5 = useRef(null)
	const ref6 = useRef(null)
	const ref7 = useRef(null)
	const [open, setOpen] = useState(!globalState.userData?.settings?.taggingTourDone)
	const steps = [
		{
			title: 'Tag your brand assets',
			description: 'Flowsam has detected some objects in your ' + (video?.analysisType !== 3 ? 'video' : 'image') + '. Step through them one by one and select those you identify as distinctive brand assets or branded communication for inclusion in the analysis.',
		},
		{
			title: 'Object label',
			description: 'You can change the name of an object by clicking its label or pressing the return key. You can also edit object names later in the report.',
			target: () => ref1.current
		},
		{
			title: 'Object',
			description: 'Click object or tap space key to include it in the analysis. Flowsam automatically tags selected objects as Brand (green) or Communication (orange).',
			target: () => ref2.current
		},
		{
			title: 'Previous object',
			description: 'Click arrow or use left arrow key to go previous object.',
			cover: (<img alt="Keyboard left" src={keysLeft} />),
			target: () => ref3.current
		},
		{
			title: 'Select/deselect object',
			description: 'Click button or tap space key to include the object in the analysis.',
			cover: (<img alt="Space key" src={keysSpace} />),
			target: () => ref4.current
		},
		{
			title: 'Next object',
			description: 'Click arrow or use right arrow key to go next object.',
			cover: (<img alt="Keyboard right" src={keysRight} />),
			target: () => ref5.current
		},
		{
			title: 'Save',
			description: 'Save your work during object selection if you’re not ready to finish the analysis.',
			target: () => ref6.current
		},
		{
			title: 'Finish & go to the report',
			description: 'Save and finish the analysis. This will take you to the analysis report. You can always come back later and edit your objects.',
			target: () => ref7.current,
			nextButtonProps: {children:(<span>OK, got it</span>)}
		},
	]

	// get video data on mount, guid change or history change
	useEffect(() => {
		setChunksLoaded(false)
		chunkArrayRef.current = []
		origSegmentRef.current = []
		context.io.socket.get('/api/v1/video/' + guid, (data, res) => {
			if (res.statusCode === 200) {
				if (data.guid) {
					 // remove custom objects with frameCount exceeding reduced video length (due to Google not detecting objects in last 3 frames)
					data.objects = data.objects.filter(obj => obj.frameNo < data.frameCount-2)

					// object size threshold
					const thresholdW = 40 // HARD-CODED / TESTING // HJ
					const thresholdH = 40 // HARD-CODED / TESTING // HJ

					// calculate video css sizes
					const processedWidth = data.width * data.processing_scale
					const processedHeight = data.height * data.processing_scale * (1/data.streamInfo.pixelAspectRatio)
					const canvasHeightCss = maxWidth/640*360
					let canvasWidthCss = maxWidth
					if (processedHeight/processedWidth > 360/640) {
						canvasWidthCss = Math.ceil(processedWidth/processedHeight * canvasHeightCss)
					}

					// remove objects below threshold size
					data.objects = data.objects.filter(obj => obj.w * canvasWidthCss > thresholdW || obj.h * canvasHeightCss > thresholdH)

					// remove segments with no objects
					data.segments = data.segments.filter(seg => data.objects.find(o => o.segment === seg.id))

					// deep clone segment array for change reference
					origSegmentRef.current = data.segments.map(seg => ({...seg}))
					videoRef.current = data
					setVideo(videoRef.current)
				} else {
					history.push('/notfound')
				}
			} else {
				// TODO: error handling
			}
		})
	}, [context.io.socket, guid, history])

	// subscribe to events for this analysis
	useEffect(() => {
		context.io.socket.post('/api/v1/user/subscribe', { roomName: 'video-' + guid }, (data, res) => {
			if (res.statusCode !== 200) {
				// TODO: handle error
			}
		})
	}, [context.io.socket, guid])

	// conditional window unload listener
	useEffect(() => {
		hasChanges && !submitted ? window.addEventListener('beforeunload', leaveWarning) : window.removeEventListener('beforeunload', leaveWarning)
 		return () => window.removeEventListener('beforeunload', leaveWarning) // cleanup on unmount
	})

	// show info box
	function showInfo(e) {
		e.currentTarget.style.color = colors.infoBoxText
	}

	// hide  info box
	function hideInfo(e) {
		e.currentTarget.style.color = colors.infoButtonText
	}

	// warning before leaving if segment has changes
	function leaveWarning(e) {
		e.preventDefault()
		e.returnValue = 'If you leave now your cue tagging and name changes will be lost'
	}

	// cache image chunks
	if (video && !chunksLoaded && !chunkArrayRef.current.length) {
		const tmpImg = new Image()
		tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + guid + '/maps-frames/' + guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
		tmpImg.onload = checkLoaded
		chunkArrayRef.current.push(tmpImg)
	}

	// check that all chunks are loaded
	function checkLoaded(e) {
		e.target.onload = null
		if (!document.getElementById('chunk-load-pct')) {
			return
		}
		numChunksLoaded++
		if (numChunksLoaded === video.chunks) {
			setChunksLoaded(true)
		} else {
			document.getElementById('chunk-load-pct').innerHTML = (50*numChunksLoaded/video.chunks).toFixed(0) + '%'
		}
		if (chunkArrayRef.current.length < video.chunks) {
			const tmpImg = new Image()
			tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
			tmpImg.onload = checkLoaded
			chunkArrayRef.current.push(tmpImg)
		}
	}

	// submit selected cues to backend
	function submitCues(e,save) {
		save ? setSaving(true) : setFinishing(true)
		const selectedSegIds = video.segments.filter(seg => seg.cueType !== null).map(seg => seg.id)

		// FOR TEST ONLY: CLEAR CUETYPES
		//video.segments.filter(seg => seg.cueType !== null).map(seg => seg.cueType = null)
		//const updatedSegs = video.segments.filter(seg => seg.cueType === null)

		const updatedSegs = video.segments.filter(seg => seg.dirty)
		video.frontendState = { ...video.frontendState, status: save ? 'work-in-progress' : 'finished' }
		const videoUpdateRequest = {
			title: video.title,
			segIdsToDelete: segmentIdsToDeleteRef.current,
			objIdsToDelete: objectIdsToDeleteRef.current,
			segsToUpdate: updatedSegs,
			segsToAdd: segmentsToAddRef.current,
			objsToUpdate: objectsToUpdateRef.current,
			objsToAdd: objectsToAddRef.current,
			objectSegmentFilter: selectedSegIds,
			project: video.project?.id,
			frontendState: video.frontendState
		}
		// send cue selection data
		context.io.socket.post('/api/v1/video/' + guid + (save ? '?dont_calculate' : ''), videoUpdateRequest, (data, res) => {
			if (res.statusCode === 200) {
				if (save) {
					// reset after save
					objectsToAddRef.current = []
					objectsToUpdateRef.current = []
					objectIdsToDeleteRef.current = []
					segmentIdsToDeleteRef.current = []
					segmentsToAddRef.current = []
					video.segments.map(seg => seg.dirty = false)
					origSegmentRef.current = video.segments.map(seg => ({...seg})) // deep clone segment array for change reference
					savedRef.current = true
					setSaving(false)
				} else {
					// listen for succesful verification event
					setSubmitted(true)
					context.io.socket.off('video-recalc-' + guid)
					context.io.socket.on('video-recalc-' + guid, msg => { // TODO: remove listener after receiving data
						history.push('/report/' + guid)
					})
					context.io.socket.off('video-progress-' + guid)
					context.io.socket.on('video-progress-' + guid, msg => { // TODO: remove listener after receiving data
						if (msg.done.statisticsCalc < 100) {
							setSubmitProgress(msg.done.statisticsCalc)
						}
					})
				}
			} else {
				// TODO: error handling
			}
		})
	}

	// save Tour done in user settings if not already saved
	function saveTaggingTourDone() {
		if (!globalState.userData?.settings?.taggingTourDone) {
			const settings = { ...globalState.userData?.settings, taggingTourDone:true }
			context.io.socket.patch('/api/v1/user', { settings:settings }, (data, res) => { // save taggingTourDone flag in user settings
				if (res.statusCode === 200) {
					setGlobalState({ ...globalState, userData:{ ...data, settings:settings } })
				} else {
					// TODO: error handling
				}
			})
		}
		setOpen(false)
	}

	// new analysis button enter handler
	function onEnter(e) {
		e.currentTarget.style.opacity = '1'
	}

	// new analysis button leave handler
	function onLeave(e) {	
		e.currentTarget.style.opacity = '.5'
	}

	// new analysis button click handler
	function handleReportClick() {
		history.goBack()
	}

	// activate submit button if a segment has been edited and at least one cue is tagged
	const hasChanges = video && (video.segments.filter(seg => seg.dirty).length > 0 || segmentsToAddRef.current.length > 0 || segmentIdsToDeleteRef.current.length > 0 || objectsToAddRef.current.length > 0 || objectsToUpdateRef.current.length > 0 || objectIdsToDeleteRef.current.length > 0)
	const canSubmit = (hasChanges && video.segments.filter(seg => seg.cueType !== null).length > 0)

	// submit buttom or beatloader
	const inProgress = video?.frontendState?.status === 'work-in-progress'
	const submitButtons = (finishing ?
		<div>
			<div style={sumbitLoaderStyle}><BeatLoader color={colors.background3} /></div>
			<div style={submitLoaderPctStyle}>{submitProgress}%</div>
		</div> :
		<div style={{width:'100%'}}>
			<button ref={ref6} className="fs-button fs-button-blue" style={buttonStyle} tabIndex="-1" disabled={!hasChanges} onClick={e=>submitCues(e,true)}>{saving ? '\u00A0\u00A0SAVING...' : 'SAVE' + (savedRef.current && !hasChanges ? 'D' : '')}</button>
			<button ref={ref7} className="fs-button fs-button-blue" style={buttonStyle} tabIndex="-1" disabled={!canSubmit && !inProgress} onClick={submitCues}>SAVE & FINISH</button>
		</div>
	)

	// back to report-button if previous page vas a report
	const backToReportButton = (history?.location?.state?.from.indexOf('/report/' !== -1) &&
		<div style={reportButtonStyle} onMouseEnter={onEnter} onMouseLeave={onLeave} onClick={handleReportClick}>&lt;&nbsp;&nbsp;Back to report&nbsp;&nbsp;</div>
	)

	// video canvas section
	const canvas = (chunksLoaded &&
		<AnalysisCueVideoEasy
			chunks={chunkArrayRef.current}
			video={video}
			origSegments={origSegmentRef.current}
			maxWidth={maxWidth}
			forceUpdate={e=>setForceUpdate(forceUpdate+Math.random())}
			objRef1={ref1}
			objRef2={ref2}
			objRef3={ref3}
			objRef4={ref4}
			objRef5={ref5}
	 	/>
	)

	// analysis cues content
	const content = (
		// no chunks or video yet - show loader
		!video || !video.guid || !chunksLoaded ?
			<div style={loaderWrapStyle}>
				<div style={loaderStyle}>
					<BeatLoader color={colors.background3} />
				</div>
				<div id="chunk-load-pct" style={loaderPctStyle}>0%</div>
			</div>
		:
		// we got video and chunks - show content
		<div style={containerStyle}>
			<h2 style={headingStyle}><img style={iconStyle} src={iconAnalysis} alt="Analysis icon" />{!title && video ? video.title : title}</h2>
			<h3 style={subHeadingStyle}>Tag your brand assets
				<div style={{display: 'inline-block'}}>
					<div style={infoButtonStyle} onMouseEnter={showInfo} onMouseLeave={hideInfo} onClick={()=>setOpen(true)}>?</div>
				</div>
			</h3>
			<div style={videoContainerStyle}>
				{canvas}
			</div>
			{submitButtons}
			{backToReportButton}
			<Prompt when={hasChanges && !submitted} message="If you leave now your object tagging and name changes will be lost" />
			<Tour open={open} steps={steps} zIndex={10001} onClose={saveTaggingTourDone}/>
		</div>
	)

	return content
}
