import React, { useEffect, useContext, useState, useRef } from 'react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../utils/globalState'
import Sidebar from '../components/sidebar/Sidebar'
import AccountSubscription from '../components/account/AccountSubscription'
import AccountSeats from '../components/account/AccountSeats'
import AccountBilling from '../components/account/AccountBilling'
import AccountPayment from '../components/account/AccountPayment'
import AccountInvoices from '../components/account/AccountInvoices'
import Alert from '../components/common/Alert'
import { APIContext } from '../utils/api'

// component function
export default function Account() {

	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [stripeIntent, setStripeIntent] = useState()
	const [plans, setPlans] = useState()
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const history = useHistory()
	const stripeElementsOptions = { fonts:[{ family: 'Greycliff', src: 'url(https://flowsam.ai/wp-content/uploads/et-fonts/greycliff-webfont.ttf)', weight: '500' }], locale: 'en' }
	const rolesAdmin = [1,1000]
	const rolesUser = [10,1100]

	const org = globalState.userData?.organization
	const subscription = org && plans && <AccountSubscription org={org} plans={plans} />
	const seats = org && <AccountSeats org={org} /> // don’t show if only 1 user seat
	const billing = org && <AccountBilling org={org} />
	const payment = org?.companyStoreId && org?.subscriptionPlan?.id > 0 && org?.subscriptionPlan?.id < 9 && <AccountPayment org={org} /> // don’t show if not in Stripe or not a standard plan
	const invoices = org && <AccountInvoices org={org} />

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// dynamic styles
	const wrapperStyle = {
		minHeight: 'calc(100vh - 120px)',
		marginLeft: '70px'
	}

	// get stripe key
	useEffect(() => {
		context.io.socket.get('/api/v1/subscription/config', (data, res) => {
			if (res.statusCode === 200) {
				setStripeIntent(loadStripe(data.key))
				setPlans(data.plans)
			} else {
				showAlert('Error getting subscription plans: ' + res.error.message)
			}
		})
	}, [context.io.socket])

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	function goBack() {
		history.goBack()
	}

	return (stripeIntent && globalState.userData && rolesAdmin.indexOf(globalState.userData?.userrole) > -1 && org.subscriptionPlan && org.subscriptionPlan.id !== 9 ? // admin role and not trial
		<>
			<Sidebar />
			<div style={wrapperStyle}>
				<Elements options={stripeElementsOptions} stripe={stripeIntent}>
					{subscription}
					{seats}
					{billing}
					{payment}
					{invoices}
					{alert}
				</Elements>
			</div>
		</>
		: (globalState.userData && rolesUser.indexOf(globalState.userData?.userrole) > -1) || (org?.subscriptionPlan?.id === 9) ? // trial or not admin
		<>
			<Sidebar />
			<div style={wrapperStyle}>
				<Alert title='Sorry, you need to be a team manager to change your organization’s flowsam account settings' message={'Please contact your team manager' + (org?.admins && org?.admins[0] ? ' (' + org.admins[0].email + ')' : '') + ' if you need assistance with your account or you need more analyses.'} action={goBack} />
			</div>
		</>
		:
		<>
			<Sidebar />
			<div style={wrapperStyle} />
		</>
	)
}
