import React from 'react'
import styles from './styles.module.css'

// component function
export default function Footer() {

	// divider
	const divider = <span className={styles.divider}>|</span>

	return (
		<div className={styles.footerBar}>
			<p className={styles.desktop}>Flowsam<sup>®</sup>{divider}Plaiground ApS{divider}Jagtvej 200, 3.{divider}DK-2100 Copenhagen{divider}Denmark{divider}<a tabIndex="-1" href="mailto:info@flowsam.ai">info@flowsam.ai</a></p>
			<p className={styles.tablet}>Flowsam<sup>®</sup>{divider}Plaiground ApS{divider}Jagtvej 200, 3.<br/>DK-2100 Copenhagen{divider}Denmark{divider}<a tabIndex="-1" href="mailto:info@flowsam.ai">info@flowsam.ai</a></p>
			<p className={styles.mobile}>Flowsam<sup>®</sup><br/>Plaiground ApS<br/>Jagtvej 200, 3.<br/>DK-2100 Copenhagen<br/>Denmark<br/><a tabIndex="-1" href="mailto:info@flowsam.ai">info@flowsam.ai</a></p>
		</div>
	)
}
