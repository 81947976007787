// palette
const palette = {
	black: '#000000',
	darkblue: '#2d3544',
	mediumblue: '#5f7c9a',
	purple: '#9d6aa7',
	green: '#39c481',
	brightgreen: '#3ed88b',
	orange: '#ed6c04',
	darkgrey: '#404040',
	mediumgrey: '#afbdcb',
	lightgrey: '#eff1f2',
	lightergrey: '#f5f6f7',
	white: '#ffffff',
	red: '#cc0000'
}

// calculate transparency hex value (takes percent value between 0-1)
const opacity = (pct) => Math.round(pct * 255).toString(16);

// theme
export const colors = {

	// text
	text: palette.darkblue,
	textInverse: palette.white,
	textLabel: palette.darkblue + opacity(.5),
	textLabelBlue: palette.mediumblue,
	textLabelGreen: palette.green,
	textShadow: palette.black + opacity(.8),
	link: palette.green,
	error: palette.red,

	// backgrounds
	background0: palette.white,
	background1: palette.lightergrey,
	background2: palette.lightgrey,
	background3: palette.green,
	background4: palette.mediumblue,
	backgroundDashboardItem: palette.white + opacity(.75),
	backgroundOverlay: palette.darkgrey + opacity(.5),
	listItemOverlay: palette.black + opacity(.25),

	// buttons
	buttonBack: palette.green,
	buttonBackActive: palette.brightgreen,
	buttonText: palette.white,

	// borders
	borderLight: palette.darkblue + opacity(.1),
	borderMedium: palette.darkblue + opacity(.15),
	borderInverse: palette.white + opacity(.4),

	// progress bars
	progressbarFill: palette.mediumblue,
	progressbarBack: palette.lightergrey,
	progressbarBack2: palette.mediumblue + opacity(.17),
	progressbarTick: palette.darkblue + opacity(.12),
	circlebarBack: palette.lightgrey,

	// metrics
	clarity: palette.purple,
	brand: palette.green,
	comms: palette.orange,

	// icons
	cardIcon: palette.darkblue + opacity(.7),

	// switch
	switchBackground0: palette.white,
	switchBackground1: palette.lightgrey,
	switchKnobOn: palette.green,
	switchKnobOff: palette.mediumgrey,

	// drop zone
	dropZoneBorder: palette.darkblue + opacity(.4),
	dropZoneHighlight: palette.lightgrey,
	dropZoneAvatarHighlight: palette.white,

	// form
	radioButtonBack: palette.darkblue + opacity(.12),
	radioButtonSelected: palette.green,
	inputHighlight: palette.white,
	inputBack: palette.lightergrey,
	inputBorder: palette.lightergrey,
	inputBorder2: palette.darkblue + opacity(.4),
	inputPlaceholder: palette.darkblue + opacity(.6),

	// info bubbles
	infoButtonBack: palette.darkblue + opacity(.12),
	infoButtonText: palette.darkblue + opacity(.5),
	infoBoxBack: palette.lightgrey,
	infoBoxText: palette.darkblue + opacity(.8),

	// alerts
	alertShadow: palette.darkgrey + opacity(.2),
	cancelButtonBack: palette.green + opacity(.7),

	// report video
	videoShadow: palette.lightgrey,
	cueLabelBackUntagged: palette.white + opacity(.8),
	cueBorderUntagged: palette.white,
	cueBorderUntaggedShadow: palette.darkgrey,
	gazePath: palette.green,

	// reuse video
	loaderbarBack: palette.lightgrey,
	sceneButtonBackActive: palette.lightergrey,
	sceneButtonBack: palette.lightergrey + opacity(.9),

	// analysis cue video
	activeCueOverlay: palette.black,
	drawOverlay: palette.lightgrey + opacity(.4),

	// tag menu
	tagmenuBack: palette.lightgrey,
	tagmenuBackHighlight: palette.white,
	tagmenuText: palette.darkblue + opacity(.9),

	// scroll bars
	scrollbar: palette.darkblue + opacity(.15),
	scrollbarThumb: palette.darkblue + opacity(.2),
	scrollbarThumbHover: palette.darkblue + opacity(.3),

}

// global css variables
document.documentElement.style.setProperty('--text-color', colors.text)
document.documentElement.style.setProperty('--text-inverse-color', colors.textInverse)
document.documentElement.style.setProperty('--text-label-blue', colors.textLabelBlue)
document.documentElement.style.setProperty('--text-label-green', colors.textLabelGreen)

document.documentElement.style.setProperty('--bg0-color', colors.background0)
document.documentElement.style.setProperty('--bg1-color', colors.background1)
document.documentElement.style.setProperty('--bg2-color', colors.background2)
document.documentElement.style.setProperty('--bg3-color', colors.background3)
document.documentElement.style.setProperty('--bg4-color', colors.background4)
document.documentElement.style.setProperty('--bg-dashboard-item-color', colors.backgroundDashboardItem)

document.documentElement.style.setProperty('--clarity-color', colors.clarity)
document.documentElement.style.setProperty('--brand-color', colors.brand)
document.documentElement.style.setProperty('--comms-color', colors.comms)

document.documentElement.style.setProperty('--border-light-color', colors.borderLight)
document.documentElement.style.setProperty('--border-medium-color', colors.borderMedium)
document.documentElement.style.setProperty('--border-inverse-color', colors.borderInverse)
document.documentElement.style.setProperty('--border-video-color', colors.videoShadow)

document.documentElement.style.setProperty('--input-placeholder-color', colors.inputPlaceholder)

document.documentElement.style.setProperty('--scrollbar-color', colors.scrollbar)
document.documentElement.style.setProperty('--scrollbar-thumb-color', colors.scrollbarThumb)
document.documentElement.style.setProperty('--scrollbar-thumb-hover-color', colors.scrollbarThumbHover)

document.documentElement.style.setProperty('--error-color', colors.error)