import React from 'react'
import socketIOClient from 'socket.io-client'
import sailsIOClient from 'sails.io.js'

const io = sailsIOClient(socketIOClient)

// MOVE TO ENVIRONMENT CONFIG!!
io.sails.url = process.env.REACT_APP_SAILS_URL
io.sails.environment = process.env.NODE_ENV
io.sails.reconnection = true
//io.sails.autoConnect = false

export const APIContext = React.createContext()

export function APIProvider(props) {
	const value = {
		io: props.io || io
	}
	return (
		<APIContext.Provider value={value}>
			{props.children}
		</APIContext.Provider>
	)
}

export function useAPI() {
	return React.useContext(APIContext)
}
