import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './styles.module.css'

// component function
export default function TopThree(props) {

	const { analyses, type } = props
	const history = useHistory()
	const dateOptions = { year:'numeric', month:'short', day:'numeric', hour:'numeric', minute:'numeric' }
	const finishedAnalyses = analyses?.filter(a => a.frontendState?.status === 'finished')

	var items
	if (type === 'clarity') items = finishedAnalyses?.sort((a, b) => a.attentionEntropy - b.attentionEntropy).slice(0,3) // attentionEntropy by lowest first
	if (type === 'brand')   items = finishedAnalyses?.sort((a, b) => b.scoreCueTypeCategoryAttention[1] - a.scoreCueTypeCategoryAttention[1]).slice(0,3)
	if (type === 'comms')   items = finishedAnalyses?.sort((a, b) => b.scoreCueTypeCategoryAttention[2] - a.scoreCueTypeCategoryAttention[2]).slice(0,3)

	// go to report
	function goReport(guid) {
		history.push('/report/' + guid)
	}

	const todoList = items?.map((item,i) => { 
		return (
			<div key={i} className={styles.itemContainer + ' ' + styles[type]} onClick={e=>goReport(item.guid)}>
				<div className={styles.rank}>{i+1}</div>
				<div className={styles.titleContainer}>
					<div className={styles.title}>{item.title}</div>
					<div className={styles.date}>{new Date(item.createdAt).toLocaleDateString("en-GB", dateOptions)}</div>
				</div>
				<div className={styles.scoreBox}>{
					type === 'clarity' ? Math.round((1-item.attentionEntropy) * 100) :
					type === 'brand' ? Math.round(item.scoreCueTypeCategoryAttention[1]) :
					Math.round(item.scoreCueTypeCategoryAttention[2])					
				}</div>
			</div>
		)
	})

	const listContent = items ? items.length > 0 ? todoList : <div className={styles.noAnalyses}>No analyses to rank</div> : null

	return (
		<div className={styles.container}>
			<div className={styles.heading}>Top 3 - {type === 'clarity' ? 'Visual Clarity' : type === 'brand' ? 'Brand Attention' : 'Comm. Attention'}</div>
			{listContent}
		</div>
	)
}
