// asset type array (TMP)
export const assetTypes = [
	{
		id: 1,
		analysisType: 1,
		mediaType: 'video',
		label: 'TVC',
		icon: 'icon-tvc.svg',
		iconHighlight: 'icon-tvc-white.svg',
		info: 'TV commercials ipsum dolor sit amet, consetetur sadipscing elitr sed.',
		vpos: 1,
		minRatio: 1.748 // 16:9 (1.77) minus allowed margin .022
	},
	{
		id: 2,
		analysisType: 1,
		mediaType: 'video',
		label: 'Online video',
		icon: 'icon-olv.svg',
		iconHighlight: 'icon-olv-white.svg',
		info: 'Online video ipsum dolor sit amet, consetetur sadipscing elitr sed.',
		vpos: 0
	},
	{
		id: 3,
		analysisType: 1,
		mediaType: 'video',
		label: 'Social media',
		icon: 'icon-some.svg',
		iconHighlight: 'icon-some-white.svg',
		info: 'Social media video ipsum dolor sit amet, consetetur sadipscing elitr sed.',
		vpos: 0
	},
	{
		id: 4,
		analysisType: 1,
		mediaType: 'video',
		label: 'Adshell/billboard',
		icon: 'icon-adshell.svg',
		iconHighlight: 'icon-adshell-white.svg',
		info: 'Adshell/billboard video ipsum dolor sit amet, consetetur sadipscing elitr sed.',
		vpos: 1
	},
	{
		id: 5,
		analysisType: 1,
		mediaType: 'video',
		label: 'Cinema',
		icon: 'icon-tvc.svg',
		iconHighlight: 'icon-tvc-white.svg',
		info: 'Cinema video ipsum dolor sit amet, consetetur sadipscing elitr sed.',
		vpos: 1,
		minRatio: 1.748 // 16:9 (1.77) minus allowed margin .022
	},
	{
		id: 6,
		analysisType: 2,
		mediaType: 'video',
		label: 'Animatic',
		icon: 'icon-animatic.svg',
		iconHighlight: 'icon-animatic-white.svg',
		info: 'Animatic ipsum dolor sit amet, consetetur sadipscing elitr sed.',
		vpos: -4
	},
	{
		id: 7,
		analysisType: 3,
		mediaType: 'image',
		label: 'Social media',
		icon: 'icon-some-img.svg',
		iconHighlight: 'icon-some-img-white.svg',
		info: 'Social media images ipsum dolor sit amet, consetetur sadipscing elitr sed.',
		vpos: 0
	},
	{
		id: 8,
		analysisType: 3,
		mediaType: 'image',
		label: 'Print ad',
		icon: 'icon-print.svg',
		iconHighlight: 'icon-print-white.svg',
		info: 'Print ads ipsum dolor sit amet, consetetur sadipscing elitr sed.',
		vpos: -1
	},
	{
		id: 9,
		analysisType: 3,
		mediaType: 'image',
		label: 'Poster/Signage',
		icon: 'icon-poster-signage.svg',
		iconHighlight: 'icon-poster-signage-white.svg',
		info: 'Posters ipsum dolor sit amet, consetetur sadipscing elitr sed.',
		vpos: 1
	},
	{
		id: 10,
		analysisType: 3,
		mediaType: 'image',
		label: 'Adshell/billboard',
		icon: 'icon-adshell-still.svg',
		iconHighlight: 'icon-adshell-still-white.svg',
		info: 'Adshell/billboard posters ipsum dolor sit amet, consetetur sadipscing elitr sed.',
		vpos: 1
	}
]
