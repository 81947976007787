import React, { useState } from 'react'
import styles from './styles.module.css'
import VideoOrImage from './VideoOrImage'
import AssetType from './AssetType'
import Industry from './Industry'
import AdInfo from './AdInfo'

// component function
export default function AnalysisForm(props) {

	const { action } = props

	const [mediaType, setMediaType] = useState()
	const [mediaFile, setMediaFile] = useState()
	const [mediaRatio, setMediaRatio] = useState()
	const [assetTypeId, setAssetTypeId] = useState()
	const [subIndustryId, setSubIndustryId] = useState()

	return (
		<>
			<div className={styles.wrapper}>
				<h2>New analysis</h2>
				<VideoOrImage setMediaType={setMediaType} setMediaFile={setMediaFile} setMediaRatio={setMediaRatio} />
				<AssetType mediaType={mediaType} setAssetTypeId={setAssetTypeId} mediaRatio={mediaRatio} />
				<Industry assetTypeId={assetTypeId} setSubIndustryId={setSubIndustryId} />
				<AdInfo mediaFile={mediaFile} assetTypeId={assetTypeId} subIndustryId={subIndustryId} submitFormData={action} />
			</div>
		</>
	)
}
