import React, { useEffect, useRef, useContext } from 'react'
import { BeatLoader } from 'react-spinners'
import { colors } from '../../../themes/colors'
import { GlobalContext } from '../../../utils/globalState'
import Switch from 'react-switch'
import useLocalStorage from '../../../utils/useLocalStorage'
import videoPlay from '../../../assets/images/video-play.svg'
import videoPause from '../../../assets/images/video-pause.svg'
import styles from './styles.module.css'

// component function
export default function ReportVideo(props) {

	const { video } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const [reportSettings, setReportSettings] = useLocalStorage('reportVideoSettings', { showAttention:true, showExtremaPoints:true })
	const chunksLoadedRef = useRef(false)
	const chunksLoadingRef = useRef(false)
	const showAttentionRef = useRef(reportSettings.showAttention)
	const showExtremaPointsRef = useRef(reportSettings.showExtremaPoints)
	const reqAnimIdRef = useRef()
	const isPlayingRef = useRef(false)
	const frameRef = useRef(0)
	const displayFrameNoRef = useRef(false)
	const chunkArrayRef = useRef([])
	const chunkArrayAttRef = useRef([])
	const canvasRef = useRef()
	const ctxRef = useRef()
	const videoContainerRef = useRef()
	const controlsContainerRef = useRef()
	const chunkLoadPctRef = useRef()
	const vtimeRef = useRef()
	const vcontrolRef = useRef()
	const globalStateRef = useRef(globalState)
	const maxWidth = 1040
	const maxHeight = 820
	const videoRatio = video ? video.width / (video.height *  (1/video.streamInfo.pixelAspectRatio)) : 1.78
	const videoWidth = videoRatio < 1 ? maxHeight * videoRatio : maxWidth
	const videoHeight = videoRatio < 1 ? maxHeight : videoWidth * 1/videoRatio
	const canvasWidth = videoWidth * window.devicePixelRatio
	const canvasHeight = videoHeight * window.devicePixelRatio
	const interval = 1000/video.frameRate
	const isStillImage = video?.analysisType === 3 // still image analysis
	const isMobile = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0)
	
	let then = Date.now()
	let mouseDownX = 0
	let lastMouseX = 0
	let draggerX = 0
	let numChunksLoaded = 0
	let spacePressed = false

	globalStateRef.current = globalState

	// dynamic styles
	const videoContainerStyle = {
		margin: '0 auto' + (isStillImage ? ' 60px' : ''),
	}
	
	const canvasStyle = {
		maxWidth: videoWidth
	}

	// set refs (for js functions that don’t get state)
	showAttentionRef.current = reportSettings.showAttention
	showExtremaPointsRef.current = reportSettings.showExtremaPoints

	// context- & canvas refs & other inits on mount
	useEffect(() => {
		// video canvas init
		ctxRef.current = canvasRef.current.getContext('2d')
		ctxRef.current.imageSmoothingEnabled = false
		ctxRef.current.font = (14 * window.devicePixelRatio) + 'px Greycliff demibold'

		// space bar video control
		window.addEventListener('keydown', keyPressed)
		window.addEventListener('keyup', keyReleased)
		// check if canvas font is loaded
		document.fonts?.addEventListener('loadingdone', fontsDidLoad) // not IE/Edge
		// cleanup on unmount
		return () => {
			cancelAnimationFrame(reqAnimIdRef.current)
			window.removeEventListener("keydown", keyPressed)
			window.removeEventListener("keyup", keyReleased)
			document.fonts?.removeEventListener('loadingdone', fontsDidLoad) // not IE/Edge
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// render video frame on component updates
	useEffect(() => {
		renderFrame(frameRef.current)
 	})

	// fonts loaded - update canvas
	function fontsDidLoad(e) {
		renderFrame(frameRef.current)
	}

	// check if video is inside viewport - enable video controls if more than half of the video is visible
	function isInViewport(elem) {
		const distance = elem.getBoundingClientRect()
		const vHeight = videoContainerRef.current.clientHeight
		return (
			distance.top >= -vHeight/2 &&
			distance.left >= 0 &&
			distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) + vHeight/2 &&
			distance.right <= (window.innerWidth || document.documentElement.clientWidth)
		)
	}

	// mouse down on player control - start drag (or click)
	function startDrag(e) {
		if (!chunksLoadedRef.current) { // prevent drag/play while preloading
			return
		}
		const controlsWidth = controlsContainerRef.current.clientWidth
		mouseDownX = lastMouseX = isMobile ? e.touches[0].clientX : e.clientX
		draggerX = parseFloat(vcontrolRef.current.style.left, 10)/100 * controlsWidth
		window.addEventListener(isMobile ? "touchmove" : "mousemove", dragController)
		window.addEventListener(isMobile ? "touchend" : "mouseup", stopDrag)
		isPlayingRef.current && pauseVideo(true)
	}

	// mouse up on player control - stop drag (or end click)
	function stopDrag() {
		cancelAnimationFrame(reqAnimIdRef.current)
		window.removeEventListener(isMobile ? "touchmove" : "mousemove", dragController)
		window.removeEventListener(isMobile ? "touchend" : "mouseup", stopDrag)
		if (mouseDownX !== lastMouseX) {
			isPlayingRef.current = true
		}
		toggleVideoPlay()
	}

	// drag controller
	function dragController(e) {
		lastMouseX = isMobile ? e.touches[0].clientX : e.clientX
		reqAnimIdRef.current = requestAnimationFrame(updateController)
	}

	// update controller position
	function updateController() {
		const controlsWidth = controlsContainerRef.current.clientWidth
		let dist = lastMouseX - mouseDownX
		dist = dist < -draggerX ? -draggerX : dist > controlsWidth-draggerX ? controlsWidth-draggerX : dist
		const leftPos = draggerX + dist
		frameRef.current = Math.round(leftPos/controlsWidth * (video.frameCount-3))
		renderFrame(frameRef.current)
	}

	// toggle video play/pause
	function toggleVideoPlay() {
		cancelAnimationFrame(reqAnimIdRef.current)
		isPlayingRef.current = !isPlayingRef.current
		if (isPlayingRef.current) {
			reqAnimIdRef.current = requestAnimationFrame(playFrame)
		} else {
			renderFrame(frameRef.current)
		}
		vcontrolRef.current.style.backgroundImage = 'url(' + (isPlayingRef.current ? videoPause : videoPlay) + ')'
	}

	// video play frame loop
	function playFrame() {
		reqAnimIdRef.current = requestAnimationFrame(playFrame)
		const now = Date.now()
		const delta = now - then
		if (delta > interval) { // check if enough time has elapsed to execute
			then = now - delta % interval
			++frameRef.current === video.frameCount-3 && pauseVideo() // video end - skipped last frame since it hasn’t any objects
			frameRef.current = frameRef.current > video.frameCount-3 ? 0 : frameRef.current
			renderFrame(frameRef.current)
		}
	}

	// pause video
	function pauseVideo(keepPlayState=false) {
		cancelAnimationFrame(reqAnimIdRef.current)
		if (!keepPlayState) {
			isPlayingRef.current = false
		}
		if (vcontrolRef.current) {
			vcontrolRef.current.style.backgroundImage = 'url(' + videoPlay + ')'
		}
	}

	// handle key events
	function keyPressed(e) {
		if (spacePressed) {
			e.preventDefault()
		} else if (e.keyCode === 32 && !isStillImage) { // spacebar 
			e.preventDefault()
			if (!chunksLoadedRef.current) { // prevent keypress play while preloading
				return
			}
			if (isInViewport(canvasRef.current)) {
				spacePressed = true
				toggleVideoPlay()
			}
		} else if (e.keyCode === 37 && !isStillImage && isInViewport(canvasRef.current)) { // left arrow
			isPlayingRef.current === true && pauseVideo()
			frameRef.current > 0 && renderFrame(--frameRef.current)
		} else if (e.keyCode === 39 && !isStillImage && isInViewport(canvasRef.current)) { // right arrow
			isPlayingRef.current === true && pauseVideo()
			frameRef.current < video.frameCount-3 && renderFrame(++frameRef.current)
		}
	}

	// cancel spacebar pressed flag
	function keyReleased(e) {
		if (e.keyCode === 32) {
			spacePressed = false
		}
	}

	// cut frame image from chunk and paint it on canvas, draw objects, annotations and update controls/values
	function renderFrame(rframe) {
		if (!chunksLoadedRef.current || !Number.isInteger(rframe)) { // prevent rendering while preloading or if frame is not int
			return
		}
		const ctx = ctxRef.current
		const chunk = Math.floor(rframe/30)
		const col = rframe % 5
		const row = Math.floor(rframe % 30 / 5)
		const w = chunkArrayRef.current[0].width/5
		const h = chunkArrayRef.current[0].height/6
		const selChunk = showAttentionRef.current ? chunkArrayAttRef.current[chunk] : chunkArrayRef.current[chunk]
		ctx.drawImage(selChunk, w*col, h*row, w, h, 0, 0, canvasWidth, canvasHeight)

		// gaze path on still images
		if (isStillImage && showExtremaPointsRef.current) {
			const extremaPoints = video.frames[rframe].localExtremaPoints.sort((a,b) => b.z - a.z).slice(0, 4); // sort by 4 highest points (z)
			if (extremaPoints?.length) {
				ctx.strokeStyle = colors.gazePath
				ctx.beginPath()
				ctx.lineWidth = 2 * window.devicePixelRatio
				ctx.moveTo(extremaPoints[0].x * canvasWidth, extremaPoints[0].y * canvasHeight)
				extremaPoints.map(p => {
					ctx.lineTo(p.x * canvasWidth, p.y * canvasHeight)
					return 0
				})
				ctx.stroke()
				extremaPoints.map((p, j) => {
					let backX = p.x * canvasWidth
					let backY = p.y * canvasHeight
					ctx.beginPath()
					ctx.arc(backX, backY, 34, 0, 2 * Math.PI)
					ctx.fillStyle = colors.gazePath
					ctx.fill()
					ctx.fillStyle = colors.textInverse
					ctx.fillText(j+1, backX - 3 * window.devicePixelRatio, backY + 4 * window.devicePixelRatio)
					return 0
				})
			}
		}

		// controls, score & time - showing minutes and hours as necessary on video
		if (!isStillImage && vtimeRef.current && vcontrolRef.current) {
			if (displayFrameNoRef.current) {
				vtimeRef.current.innerHTML = 'frame ' + (frameRef.current + 1)
			} else {
				const secs = rframe/video.frameRate
				const timestring = new Date(secs * 1000).toISOString()
				vtimeRef.current.innerHTML = secs > 3600 ? timestring.substring(12, 22) : secs > 600 ? timestring.substring(14, 22) : timestring.substring(15, 22)
			}

			// position video player control knob and time container
			const playedPct = rframe/(video.frameCount-3) * 100
			vcontrolRef.current.style.left = playedPct + '%'
			vtimeRef.current.style.left = playedPct + '%'
		}
	}

	// cache image chunks
	if (video && !chunksLoadingRef.current && !chunksLoadedRef.current) {

		chunksLoadingRef.current = true;

		const tmpImg = new Image()
		tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
		tmpImg.onload = checkLoaded
		chunkArrayRef.current.push(tmpImg)

		const tmpAttImg = new Image()
		tmpAttImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames-blend/' + video.guid + '-chunk' + ("0000000"+chunkArrayAttRef.current.length).slice(-7) + '.jpg'
		tmpAttImg.onload = checkLoadedAtt
		chunkArrayAttRef.current.push(tmpAttImg)
	}

	// check that all chunks are loaded
	function checkLoaded(e) {
		e.target.onload = null
		if (!chunkLoadPctRef.current) {
			return
		}
		numChunksLoaded++
		if (numChunksLoaded === 2 * video.chunks) { // with and without attention heatmap
			chunksLoadedRef.current = true
			setGlobalState({...globalStateRef.current, reportVideoCached:true}) // tell other report components that the report video has been cached
		} else {
			chunkLoadPctRef.current.innerHTML = (50*numChunksLoaded/video.chunks).toFixed(0) + '%'
		}

		if (chunkArrayRef.current.length < video.chunks) {
			const tmpImg = new Image()
			tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
			tmpImg.onload = checkLoaded
			chunkArrayRef.current.push(tmpImg)
		}
	}

	// check that all attention chunks are loaded
	function checkLoadedAtt(e) {
		e.target.onload = null
		if (!chunkLoadPctRef.current) {
			return
		}
		numChunksLoaded++
		if (numChunksLoaded === 2 * video.chunks) { // with and without attention heatmap
			chunksLoadedRef.current = true
			setGlobalState({...globalStateRef.current, reportVideoCached:true}) // tell other report components that the report video has been cached
		} else {
			chunkLoadPctRef.current.innerHTML = (50*numChunksLoaded/video.chunks).toFixed(0) + '%'
		}

		if (chunkArrayAttRef.current.length < video.chunks) {
			const tmpAttImg = new Image()
			tmpAttImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames-blend/' + video.guid + '-chunk' + ("0000000"+chunkArrayAttRef.current.length).slice(-7) + '.jpg'
			tmpAttImg.onload = checkLoadedAtt
			chunkArrayAttRef.current.push(tmpAttImg)
		}
	}

	// toggle time/frameNo display
	function toggleFrameNoDisplay() {
		displayFrameNoRef.current = !displayFrameNoRef.current
		renderFrame(frameRef.current)
	}

	// toggle attention visibility
	function toggleAttention() {
		setReportSettings({...reportSettings, showAttention:!reportSettings.showAttention})
	}

	// toggle extrema points visibility
	function toggleExtremaPoints() {
		setReportSettings({...reportSettings, showExtremaPoints:!reportSettings.showExtremaPoints})
	}

	// jump to frame when clicked/tapped on timeline
	function jumpToFrame(e) {
		const clientX = isMobile ? e.touches[0].clientX : e.clientX
		if (clientX - e.currentTarget.getBoundingClientRect().x - 15 > e.currentTarget.getBoundingClientRect().width-15) return
		let gotoFrame = Math.round((clientX - e.currentTarget.getBoundingClientRect().x - 15)/(e.currentTarget.getBoundingClientRect().width-30) * (video.frameCount-3))
		if (gotoFrame < 0) gotoFrame = 0
		if (gotoFrame > video.frameCount-3) gotoFrame =  video.frameCount-3
		frameRef.current = gotoFrame
		isPlayingRef.current === true && pauseVideo()
		renderFrame(frameRef.current)
	}

	// canvas
	const canvas = <canvas style={canvasStyle} className={styles.canvas} ref={canvasRef} width={canvasWidth} height={canvasHeight} onTouchStart={isStillImage ? null : startDrag} onMouseDown={isStillImage ? null : isMobile ? null : startDrag}/>

	// preloader
	const preLoader = (!chunksLoadedRef.current &&
		<div className={styles.loaderContainer}>
			<BeatLoader color={colors.background3} />
			<div ref={chunkLoadPctRef} className={styles.loaderPct}>0%</div>
		</div>
	)

	// video controls
	const controls = (!isStillImage &&
		<>
			<div className={styles.lineContainer} onTouchStart={jumpToFrame} onMouseDown={isMobile ? null : jumpToFrame}>
				<div className={styles.line} />
			</div>
			<div ref={vcontrolRef} className={styles.dragger} onTouchStart={startDrag} onMouseDown={isMobile ? null : startDrag} />
			<div ref={vtimeRef} className={styles.time} onClick={toggleFrameNoDisplay}>0:00.00</div>
		</>
	)

	// dynamic heading
	const gazeFlowText = isStillImage && ' & gaze flow' // still image

	// toggles
	const heatmapToggle = (
		<div className={styles.switchContainer}>
			<div onClick={toggleAttention}>Heatmap</div>
			<Switch
				onChange={toggleAttention}
				checked={reportSettings.showAttention}
				offColor={colors.switchBackground1}
				offHandleColor={colors.switchKnobOff}
				onColor={colors.switchBackground1}
				onHandleColor={colors.switchKnobOn}
				uncheckedIcon={false}
				checkedIcon={false}
				height={16}
				width={30}
				handleDiameter={16}
				activeBoxShadow=''
				id='heatmap-switch'
			/>
		</div>
	)

	const gazeflowToggle = (isStillImage &&
		<div className={styles.switchContainer}>
			<div onClick={toggleExtremaPoints}>Gaze flow</div>
			<Switch
				onChange={toggleExtremaPoints}
				checked={reportSettings.showExtremaPoints}
				offColor={colors.switchBackground1}
				offHandleColor={colors.switchKnobOff}
				onColor={colors.switchBackground1}
				onHandleColor={colors.switchKnobOn}
				uncheckedIcon={false}
				checkedIcon={false}
				height={16}
				width={30}
				handleDiameter={16}
				activeBoxShadow=''
				id='gazeflow-switch'
			/>
		</div>
	)

	return (
		<div className={styles.background}>
			<div className={styles.header}>
				<div className={styles.heading}>Attention heatmap{gazeFlowText}</div>
				<div className={styles.switchesContainer}>
					{heatmapToggle}
					{gazeflowToggle}
				</div>
			</div>
			<div ref={videoContainerRef} style={videoContainerStyle} className={styles.videoWrapper}>
				{canvas}
				{preLoader}
			</div>
			<div ref={controlsContainerRef} className={styles.controlsContainer}>
				{controls}
			</div>
		</div>
	)
}
