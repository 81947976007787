import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { APIProvider } from './utils/api'
import { GlobalProvider } from './utils/globalState'
import { BrowserRouter as Router } from 'react-router-dom'
import './styles/index.css'

const rootContent = (
	<APIProvider>
		<GlobalProvider>
			<Router>
				<App />
			</Router>
		</GlobalProvider>
	</APIProvider>
)

ReactDOM.render(rootContent, document.getElementById('root'))
