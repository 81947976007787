import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../utils/globalState'
import { colors } from '../../themes/colors'

// styles
const barContainerStyle = {
	display: 'flex',
	marginTop: '-18px',
	transition: 'padding-left .3s'
}

// component function
export default function AnalysisCueVideoObjectEasy(props) {

	const { objects, segment, origSegment, width, height, forceUpdate, toggleSegment, nameMaxChars, objRef1, objRef2 } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [segmentName, setSegmentName] = useState(segment.name.slice(0,nameMaxChars))
	const stroke = segment.cueType === null ? 'dashed' : 'solid'
	const cueTypeCategory = globalState.cueTypes.find(cueType => cueType.id === segment.cueType)?.cueTypeCategory
	const cueColor = cueTypeCategory === 1 ? colors.brand : cueTypeCategory === 2 ? colors.comms : colors.background0

	// dynamic styles
	const objectContainerStyle = {
		position: 'relative',
		width: width,
		height: height,
		margin: -height + 'px auto 0'
	}

	const objectStyle = {
		position: 'absolute',
		border: '2px ' + stroke + ' ' + cueColor,
		boxSizing: 'border-box',
		marginTop: '-2px',
		cursor: 'pointer',
		boxShadow: '0 0 80px 0 rgba(0,0,0,0.06)'		
	}
	
	const objectStyle2 = {
		position: 'absolute',
		marginTop: '-2px',
		boxShadow: '0 0 80px 0 rgba(0,0,0,0.05) inset'		
	}

	const objectTitleStyle = {
		position: 'relative',
		width: '0',
		height: '17px',
 		color: segment.cueType !== null ? colors.textInverse : colors.text,
 		backgroundColor: cueColor,
		pointerEvents: 'auto'
	}

	const measureTitleStyle = {
		...objectTitleStyle,
		width: 'auto',
		lineHeight: '18px',
		padding: '0 7px 1px',
		whiteSpace: 'pre'
	}

	// space bar/arrow key video control override listeners
	useEffect(() => {
		document.addEventListener("keydown", focusLabel)
		document.getElementById('label')?.addEventListener("keydown", keyOverride)
		return () => {
			document.removeEventListener("keydown", focusLabel)
			document.getElementById('label')?.removeEventListener("keydown", keyOverride) // cleanup on unmount
		}
 	},[objects])

	// adjust label input width based on content of hidden span
	useEffect(() => {
		if (document.getElementById('inputMeasure')) {
			const w = document.getElementById('inputMeasure').offsetWidth
			document.getElementById('label').style.width = w + 'px'
			document.getElementById('label').style.marginLeft = -w + 'px'
		}
 	})

	// update object title on prop change
	useEffect(() => {
		setSegmentName(segment.name.slice(0,nameMaxChars))
	}, [segment.name, nameMaxChars])

	// override video spacebar, arrows and backspace keys when editing segment name
	function keyOverride(e) {
		(e.keyCode === 13 || e.keyCode === 27) && e.target.blur() // exit input on return or escape key
		e.keyCode === 8|32|37|39 && e.stopPropagation()
	}

	// focus label input on return key pressed
	function focusLabel(e) {
		const label = document.getElementById('label')
		label && e.keyCode === 13 && document.activeElement !== label && label.select()
	}

	// change segment title in received segment reference
	function saveSegmentName(e) {
		segment.name = e.target.value
		segment.dirty = segment.id < 0 ? false : segment.cueType === origSegment.cueType && segment.name.slice(0,nameMaxChars) === origSegment.name.slice(0,nameMaxChars) ? false : true // set 'dirty' on segment depending on diff from original value
		setSegmentName(e.target.value)
		forceUpdate() // force update parent
	}

	// object border(s)
	const objectsToDraw = objects.map(o => {

		// object position
		const objWrapperStyle = { position: 'absolute', top: o.y*height+2, left: o.x*width, zIndex: '700' }
		const objStyle = { ...objectStyle, width: o.w*width, height: o.h*height }
		const objStyle2 = { ...objectStyle2, width: o.w*width, height: o.h*height, marginBottom: -o.h*height } // inner shadow to make dotted border visible on white background

		// object edit bar
		const objectBar = (
			<div ref={objRef1} style={barContainerStyle}>
				<div id="inputMeasure" className="fs-object-title-input" style={measureTitleStyle}>{segmentName}</div>
				<input
					id="label"
					className="fs-object-title-input"
					style={objectTitleStyle}
					tabIndex="-1"
					spellCheck="false"
					value={segmentName}
					onChange={saveSegmentName}
					maxLength={nameMaxChars}
				/>
			</div>
		)

		return (
			<div key={o.id} id={o.id} style={objWrapperStyle} >
				<div style={objStyle2} />
				<div ref={objRef2} id={'obj'+o.id} style={objStyle} onClick={toggleSegment} />
				{objectBar}
			</div>
		)}
	)

	return (
		<div style={objectContainerStyle}>
			{objectsToDraw}
		</div>
	)
}
