import React, { useContext } from 'react'
import { GlobalContext } from '../utils/globalState'
import { colors } from '../themes/colors'
import Sidebar from '../components/sidebar/Sidebar'
import AnalysisList from '../components/analysis-list/AnalysisList'

// component function
export default function MyAnalyses() {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars

	// dynamic styles
	const wrapperStyle = {
		minHeight: 'calc(100vh - 120px)',
		minWidth: '768px',
		marginLeft: '70px',
		backgroundColor: colors.background1
	}

	// step content
	const content = (
		// not logged in
		!globalState.loggedIn ?
			null
		:
		// logged in - show list
		<AnalysisList />
	)

	return (
		<>
			<Sidebar />
			<div style={wrapperStyle}>
				{content}
			</div>
		</>
	)
}
