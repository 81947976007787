import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { APIContext } from '../utils/api'
import { GlobalContext } from '../utils/globalState'

// styles
const loginStyle = {
	height: 'calc(100vh - 120px)',
	minHeight: '400px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	margin: '0 auto'
}

// component function
export default function Login(props) {

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const context = useContext(APIContext)
	const history = useHistory()

	// log in
	function login(e) {
		e.preventDefault()
		context.io.socket.post('/api/v1/user/login?recaptcha_ignore=dc764bab-77a9-40a6-a734-cc83cc07f11b', {email:email, password:password}, (data, res) => {
			if (res.statusCode === 200) {
				setGlobalState({ ...globalState, loggedIn:true, userData:data })
				history.push('/')
			} else {
				// TODO: error handling
			}
		})
	}

	return (
		<form onSubmit={login}>
			<div style={loginStyle}>
				<h3 style={{paddingBottom:'40px'}}>Log in to flowsam<sup>®</sup></h3>
				<input
					name="email"
					placeholder="E-mail"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className="fs-input-text"
					autoComplete="username"
				/>
				<input
					type="password"
					name="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className="fs-input-text"
					autoComplete="current-password"
				/><br />
				<button className="fs-button" tabIndex="-1">LOGIN</button>
			</div>
		</form>
	)
}
