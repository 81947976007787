import React from 'react'
import styles from './styles.module.css'

// component function
export default function SystemStatus(props) {

	const {status} = props

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.heading}>Support</div>
				<a href="mailto:support@flowsam.ai" className={styles.cta}>Contact us at support@flowsam.ai</a>
			</div>
			<div className={styles.container}>
				<div className={styles.heading}><span className={styles.status + ' ' + (status === 1 ? styles.statusOk : styles.statusErr)} />System status</div>
				<div className={styles.statusText}>{status === 1 ? 'All flowsam systems are operating at full capacity.' : 'We are currently having some technical issues.'}</div>
			</div>
		</div>
	)
}
