import React, { useState, useEffect, useRef } from 'react'
import styles from './styles.module.css'
import { IntervalTimer } from '../../../utils/IntervalTimer'
import AverageSlide from './AverageSlide'

// component function
export default function Benchmarks(props) {

	const { benchmarks } = props
	const [title, setTitle] = useState('Visual Clarity')
	const sliderRef = useRef()
	const slidesRef = useRef()
	const activeSlideRef = useRef(1)
	const slidingRef = useRef(false)
	const intervalRef = useRef()

	// slides setup
	useEffect(() => {
		// put slides in array
		slidesRef.current = [
			document.getElementById('slide-0'),
			document.getElementById('slide-1'),
			document.getElementById('slide-2')
		]
		// start slide auto-animation
		intervalRef.current = new IntervalTimer(goRight, 4000)
		intervalRef.current.start()
		return () => intervalRef.current.stop() // stop slide animation timer on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	// left arrow action
	function goLeft() {
		intervalRef.current.stop()
		if (slidingRef.current) return
		if (--activeSlideRef.current < 0) activeSlideRef.current = 2
		sliderRef.current.addEventListener('transitionend', placeSlides)
		sliderRef.current.style.transition = 'left .5s'
		sliderRef.current.style.left = parseInt(sliderRef.current.style.left.split('%')[0]) + 100 + '%'
		slidingRef.current = true
		setTitle(activeSlideRef.current === 0 ? 'Comm. Attention' : activeSlideRef.current === 1 ? 'Visual Clarity' : 'Brand Attention')
	}

	// right arrow action
	function goRight(e) {
		if (e !== undefined) intervalRef.current.stop()
		if (slidingRef.current) return
		if (++activeSlideRef.current > 2) activeSlideRef.current = 0
		sliderRef.current.addEventListener('transitionend', placeSlides)
		sliderRef.current.style.transition = 'left .5s'
		sliderRef.current.style.left = parseInt(sliderRef.current.style.left.split('%')[0]) - 100 + '%'
		slidingRef.current = true
		setTitle(activeSlideRef.current === 0 ? 'Comm. Attention' : activeSlideRef.current === 1 ? 'Visual Clarity' : 'Brand Attention')
	}

	// go to selected slide
	function goSlide(s) {
		intervalRef.current.stop()
		if (activeSlideRef.current === s) return
		if (activeSlideRef.current === 1) {
			s === 0 ? goLeft() : goRight()
		} else if (activeSlideRef.current === 0) {
			s === 1 ? goRight() : goLeft()
		} else {
			s === 1 ? goLeft() : goRight()
		}
	}

	// pause slider
	function pauseSlider() {
		intervalRef.current?.pause()
	}

	// resume slider
	function resumeSlider() {
		intervalRef.current?.resume()
	}

	// position slides after animation ends
	function placeSlides() {
		sliderRef.current.removeEventListener('transitionend', placeSlides)
		if (activeSlideRef.current === 0) {
			slidesRef.current[0].style.order = 2
			slidesRef.current[1].style.order = 3
			slidesRef.current[2].style.order = 1
		}
		if (activeSlideRef.current === 1) {
			slidesRef.current[0].style.order = 1
			slidesRef.current[1].style.order = 2
			slidesRef.current[2].style.order = 3
		}
		if (activeSlideRef.current === 2) {
			slidesRef.current[0].style.order = 3
			slidesRef.current[1].style.order = 1
			slidesRef.current[2].style.order = 2
		}
		sliderRef.current.style.transition = 'none'
		sliderRef.current.style.left = '-100%'
		slidingRef.current = false
	}

	return (
		<div className={styles.container}>
			<div className={styles.heading}>Benchmarks - {title}</div>
			<div className={styles.benchmarkContainer} onMouseEnter={pauseSlider} onMouseLeave={resumeSlider}>
				<div className={styles.arrow + ' ' + styles.arrowLeft} onClick={goLeft}/>
				<div className={styles.slidesContainer}>
					<div ref={sliderRef} id='slider' className={styles.slider} style={{left:'-100%'}}>
						<AverageSlide type='comms' scores={benchmarks.comms} />
						<AverageSlide type='clarity' scores={benchmarks.clarity} />
						<AverageSlide type='brand' scores={benchmarks.brand} />
					</div>
				</div>
				<div className={styles.arrow + ' ' + styles.arrowRight} onClick={goRight}/>
			</div>
			<div className={styles.dotsContainer}>
				<div className={styles.dot + ' ' + styles.clarity + ' ' + (activeSlideRef.current === 1 && styles.active)} onClick={e=>goSlide(1)} />
				<div className={styles.dot + ' ' + styles.brand + ' ' + (activeSlideRef.current === 2 && styles.active)} onClick={e=>goSlide(2)} />
				<div className={styles.dot + ' ' + styles.comms + ' ' + (activeSlideRef.current === 0 && styles.active)} onClick={e=>goSlide(0)} />
			</div>
		</div>
	)
}
