export class IntervalTimer {
	callbackStartTime = new Date().getTime();
	remaining;
	paused = false;
	stopped = false;
	timerId = null;
	timeoutId = null;
	_callback;
	_delay;

	constructor(callback, delay) {
		this._callback = callback;
		this._delay = delay;
	}

	pause() {
		if (!this.paused && !this.stopped) {
			this.paused = true;
			this.clear();
			this.remaining = this._delay - (new Date().getTime() - this.callbackStartTime);
		}
	}

	resume() {
		if (this.paused && !this.stopped) {
			this.paused = false;
			if (this.remaining) {
				this.timeoutId = setTimeout(() => {
					this.run();
					this.start();
				}, this.remaining);
			} else {
				this.start();
			}
		}
	}

	clear() {
		clearInterval(this.timerId);
		clearTimeout(this.timeoutId);
	}

	start() {
		this.stopped = false;
		this.clear();
		this.timerId = setInterval(() => {
			this.run();
		}, this._delay);
	}

	run() {
		this.callbackStartTime = new Date().getTime();
		this._callback();
	}

	stop() {
		this.stopped = true;
		this.clear();
	}
}