import React  from 'react'
import { colors } from '../../themes/colors'

// styles
const leftTextStyle = {
	width: '50%',
	paddingRight: '70px',
	boxSizing: 'border-box'
}

const rightTextStyle = {
	width: '50%',
	paddingLeft: '70px',
	boxSizing: 'border-box'
}

const iconStyle = {
	marginBottom: '15px'
}

const containerStyle = {
	display: 'flex',
	width: '100%', // for IE compatibility
	minWidth: '688px',
	maxWidth: '1080px',
	margin: '0 auto',
	paddingBottom: '70px',
	boxSizing: 'border-box',
	textAlign: 'left',
	fontSize: '18px',
	lineHeight: '26px',
	color: colors.text
}

// component function
export default function UserGuideSection(props) {

	const { icon, image, dir, border, imgTop, children } = props

	// dynamic styles
	const imageStyle = {
		display: 'block',
		width: '100%',
		border: border ? '1px solid ' + colors.borderLight : 'none',
		boxSizing: 'border-box'
	}

	const imageBoxStyle = {
		width: '50%',
		boxSizing: 'border-box',
		marginTop: imgTop !== undefined ? imgTop + 'px' : '5px'
	}

	const iconContent = icon && <img src={icon} style={iconStyle} alt="" />

	const textContent = (
		<>
			{iconContent}
			{children}
		</>
	)

	const imgContent = (
		<img src={image} style={imageStyle} alt="" />
	)

	const content = (dir === 'left' ?
		<><div style={leftTextStyle}>{textContent}</div><div style={imageBoxStyle}>{imgContent}</div></> :
		<><div style={imageBoxStyle}>{imgContent}</div><div style={rightTextStyle}>{textContent}</div></>
	)

	return (
		<div style={containerStyle}>
			{content}
		</div>
	)
}
