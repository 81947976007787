// industries array (TMP)
export const industryOptions = [
	{ value: 0, label: 'Automotive' },
	{ value: 1, label: 'Education' },
	{ value: 2, label: 'Fashion & Beauty' },
	{ value: 3, label: 'Finance' },
	{ value: 4, label: 'Food & Beverage' },
	{ value: 5, label: 'Health Care' },
	{ value: 6, label: 'Home & Family' },
	{ value: 7, label: 'Industrial' },
	{ value: 8, label: 'Media & Communication' },
	{ value: 9, label: 'Music & Entertainment' },
	{ value: 10,label: 'Sports' }
]
