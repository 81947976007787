import React from 'react'
import styles from './styles.module.css'

// component function
export default function News() {

	const newsItem = {
		date: '22. June 2024',
		title: 'Introducing competitor analyses',
		text: 'ark analyses as competing brands and see them as a separate benchmark.',
		link: 'https://flowsam.ai'
	}

	function goNews() {
		window.location = newsItem.link
	}

	return (
		<div className={styles.container}>
			<div className={styles.heading}>News</div>
			<div className={styles.newsContainer} onClick={goNews}>
				<div className={styles.newsDate}>{newsItem.date}</div>
				<div className={styles.newsTitle}>{newsItem.title}</div>
				<div className={styles.newsText}>{newsItem.text}</div>
				<div className={styles.cta}>Read more &gt;</div>
			</div>
		</div>
	)
}
