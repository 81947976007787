import React from 'react'
import styles from './styles.module.css'

// component function
export default function Guides() {

	function goGuides() {
		window.location = 'https://flowsam.ai'
	}

	return (
		<div className={styles.container}>
			<div className={styles.heading}>Guides</div>
			<div className={styles.guideContainer} onClick={goGuides}>
				<div className={styles.guideText}>Master the art of predicting attention to distinctive brand assets.</div>
				<div className={styles.cta}>How-to videos &gt;</div>
			</div>
		</div>
	)
}
