import React, { useContext, useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'
import Alert from '../common/Alert'
import { APIContext } from '../../utils/api'
import { GlobalContext } from '../../utils/globalState'
import { colors } from '../../themes/colors'

// styles
const sectionContainerStyle = {
	borderTop:  '1px solid ' + colors.borderLight,
	boxSizing: 'border-box',
	padding: '35px 0 40px'
}

const headingStyle = {
	fontFamily: 'Greycliff demibold',
	color: colors.text
}

const labelStyle = {
	fontSize: '12px',
	fontFamily: 'Greycliff demibold',
	color: colors.textLabel,
	margin: '17px 0 -1px',
	whiteSpace: 'nowrap'
}

const valueStyle = {
	whiteSpace: 'nowrap',
	color: colors.text
}

const switchContainerStyle = {
	display: 'flex',
	alignItems: 'center',
	marginTop: '18px'
}

const inputStyleEnabled = {
	position: 'relative',
	width: '100%',
	minWidth: '230px',
	height: '30px',
	margin: '-2px 0',
	padding: '1px 0',
	color: colors.text,
	backgroundColor: colors.background2,
	borderBottom: '1px solid ' + colors.inputBorder2
}

const inputStyleDisabled = {
	...inputStyleEnabled,
	borderBottom: '1px solid transparent',
	userSelect: 'none',
	WebkitUserSelect: 'none',
	cursor: 'default'
}

const smallButtonStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	boxSizing: 'border-box',
	minWidth: '42px',
	height: '18px',
	borderRadius: '9px',
	backgroundColor: colors.buttonBack,
	color: colors.buttonText,
	fontSize: '12px',
	fontFamily: 'Greycliff demibold',
	margin: '6px 0 0 11px',
	padding: '1px 7px 0',
	cursor: 'pointer',
	transition: 'background-color .2s, color .2s'
}

const infoButtonStyle = {
	...smallButtonStyle,
	margin: '4px 0 0 9px',
	padding: '2px 0 0',
	minWidth: '18px',
	height: '18px',
	cursor: 'default',
	backgroundColor: colors.background0,
	color: colors.infoButtonText
}

const saveButtonStyle = {
	...smallButtonStyle,
	opacity: '.6',
	cursor: 'default',
	pointerEvents: 'none',
	transition: 'background-color .2s, color .2s'
}

const infoBoxStyle = {
	position: 'absolute',
	marginTop: '5px',
	backgroundColor: colors.background0,
	padding: '11px 18px 13px 13px',
	color: colors.infoBoxText,
	fontSize: '12px',
	lineHeight: '19px',
	fontFamily: 'Greycliff demibold',
	borderRadius: '5px',
	zIndex: '500',
	opacity: '0',
	pointerEvents: 'none',
	transition: 'opacity .35s'
}

// component function
export default function UserSettingsMenu(props) {

	const {profileFormChanged, saveUserData, settingsVisible, setSettingsVisible, editingUser, setEditingUser, logout} = props
	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const dateOptions = { year:'numeric', month:'short', day:'numeric' }

	const [userName, setUserName] = useState(globalState.userData?.name)
	const [userEmail, setUserEmail] = useState(globalState.userData?.email)
	const [userPassword, setUserPassword] = useState('')
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const history = useHistory()
	const rolesAdmin = [1,1000]

	const dataRoot = globalState.userData?.organization
	const subscriptionName = dataRoot.subscriptionPlan.name ? dataRoot.subscriptionPlan.name : 'n/a'
	const subscriptionCycle = dataRoot.subscriptionPlan.config.Calc_cycle_length_month ? dataRoot.subscriptionPlan.config.Calc_cycle_length_month : 1
	const analysesTotal = dataRoot.subscriptionPlan.config.Total_videos_month ? dataRoot.subscriptionPlan.config.Total_videos_month : 0
	const maxFilmLength = dataRoot.subscriptionPlan.config.Max_film_length ? dataRoot.subscriptionPlan.config.Max_film_length : 0
	const maxFilmMb = dataRoot.subscriptionPlan.config.Max_Mb_per_film ? dataRoot.subscriptionPlan.config.Max_Mb_per_film : 0
	const resetDate = dataRoot.subscriptionCalculations.currentPeriodEnd ? dataRoot.subscriptionCalculations.currentPeriodEnd : 0
	const userProfileValueString = globalState.userData?.name + globalState.userData?.email + ''

	// dynamic styles
	const menuBackStyle = {
		position: 'fixed',
		width: '350px',
		height: '100%',
		top: '0',
		left: settingsVisible ? '70px' : '-370px',
		backgroundColor: colors.background2,
		zIndex: '9998',
		borderRight: '1px solid ' + colors.borderLight,
		overflowY: 'auto',
		boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.06)',
		padding: '36px 60px 0',
		boxSizing: 'border-box',
		transition: 'left .7s'
	}

	// get fresh user-/account data
	useEffect(() => {
		context.io.socket.get('/api/v1/user', (data, res) => {
			if (res.statusCode === 200) {
				setGlobalState({...globalState, userData:data})
				setUserName(data.name)
				setUserEmail(data.email)
			} else {
				showAlert('Error getting user data: ' + res.error.message)
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.io.socket, settingsVisible])

	// key overrides so input doesn’t interfere with other key listeners
	useEffect(() => {
		document.getElementById('name').addEventListener("keydown", keyOverride)
		document.getElementById('name').addEventListener("keyup", keyOverride)
		document.getElementById('email').addEventListener("keydown", keyOverride)
		document.getElementById('email').addEventListener("keyup", keyOverride)
		document.getElementById('passw').addEventListener("keydown", keyOverride)
		document.getElementById('passw').addEventListener("keyup", keyOverride)
		return () => {
			document.getElementById('name').removeEventListener("keydown", keyOverride) // cleanup on unmount
			document.getElementById('name').removeEventListener("keyup", keyOverride)
			document.getElementById('email').removeEventListener("keydown", keyOverride)
			document.getElementById('email').removeEventListener("keyup", keyOverride)
			document.getElementById('passw').removeEventListener("keydown", keyOverride)
			document.getElementById('passw').removeEventListener("keyup", keyOverride)
		}
 	},[])

	// override spacebar, arrows and backspace key listeners when editing user profile
	function keyOverride(e) {
		e.keyCode !== 27 && // allow escape
		e.keyCode === 8|32|37|39 && e.stopPropagation()
	}

	// toggle show team analyses setting
	function toggleTeamAnalyses() {
		const newShowTeamAnalyses = globalState.userData?.settings?.showTeamAnalyses ? !globalState.userData.settings.showTeamAnalyses : true
		const newStateObj = {...globalState, userData:{...globalState.userData, settings:{...globalState.userData?.settings, showTeamAnalyses:newShowTeamAnalyses}}}
		setGlobalState(newStateObj)
		saveUserSettings({settings:newStateObj.userData.settings})
	}

	// toggle newsletter signup
	function toggleNewsletter() {
		const newsletterSignup = globalState.userData?.permissionType2 && globalState.userData?.permissionType2 === '1' ? '0' : '1'
		const newStateObj = {...globalState, userData:{...globalState.userData, permissionType2:newsletterSignup}}
		setGlobalState(newStateObj)
		saveUserSettings({permissionType2:newStateObj.userData.permissionType2})
	}

	// highlight button
	function onButtonEnter(e) {
		e.currentTarget.style.backgroundColor = colors.buttonBackActive
	}

	// dim button
	function onButtonLeave(e) {
		e.currentTarget.style.backgroundColor = colors.buttonBack
	}

	// show subscription info box
	function showInfo(e) {
		document.getElementById('infoBox').style.opacity = 1
	}

	// hide subscription info box
	function hideInfo(e) {
		document.getElementById('infoBox').style.opacity = 0
	}

	// save user settings
	function saveUserSettings(userDataProp) {
		context.io.socket.patch('/api/v1/user', userDataProp, (data, res) => {
			if (res.statusCode !== 200) {
				showAlert('Error saving user settings: ' + res.error.message)
			}
		})
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	let allowSubmit = true

	// save user data
	saveUserData.current = (e, closing=false) => {
		if (allowSubmit && profileFormChanged.current) { // save if profile data has changed and we’re not already saving
			if (!validateEmail(userEmail)) {
				showAlert('Error in email address')
			} else if (userName.trim().length < 1) {
				showAlert('Name can not be empty')
			} else if (userPassword.length > 0 && userPassword.length < 8) {
				showAlert('Password must be at least 8 characters')
			} else if (userPassword.indexOf(' ') > -1) {
				showAlert('Password can not contain spaces')
			} else { // all ok
				allowSubmit = false
				const request = {
					email: userEmail,
					name: userName.trim(),
					password: userPassword !== '' ? userPassword : globalState.userData?.password
				}
				// send user data
				context.io.socket.patch('/api/v1/user', request, (data, res) => {
					if (res.statusCode === 200) {
						profileFormChanged.current = false
						setGlobalState({...globalState, userData:data})
						setEditingUser(false)
						closing && setSettingsVisible(false)
					} else if (res.statusCode === 409) {
						showAlert('Email address is already in use by another account')
					} else {
						showAlert('Error saving user data: ' + res.error.message)
						setEditingUser(false)
					}
					allowSubmit = true
				})
			}
		}
	}

	// validate email
	function validateEmail(email) {
		const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i //eslint-disable-line
		return re.test(String(email).toLowerCase())
	}

	// update form values
	function updateFormValue(e) {
		e.target.id === 'name' && setUserName(e.target.value)
		e.target.id === 'email' && setUserEmail(e.target.value)
		e.target.id === 'passw' && setUserPassword(e.target.value)
		profileFormChanged.current = userProfileValueString !== (document.getElementById('name').value + document.getElementById('email').value + document.getElementById('passw').value)
		document.getElementById('saveButton').style.opacity = profileFormChanged.current ? '1' : '.6'
		document.getElementById('saveButton').style.cursor = profileFormChanged.current ? 'pointer' : 'default'
		document.getElementById('saveButton').style.pointerEvents = profileFormChanged.current ? 'auto' : 'none'
	}

	// go to signup page
	function goSignup() {
		window.location.href='https://flowsam.ai/features-plans/'
	}

	// go to account settings
	function goAccountSettings() {
		if (rolesAdmin.indexOf(globalState.userData?.userrole) > -1) {
			if (globalState.userData?.organization?.subscriptionPlan?.id === 9) {
				goSignup()
			} else {
				history.push('/account')
				setSettingsVisible(false)
			}
		}
	}

	// edit account button
	const editAccountButton = (
		globalState.userData?.organization?.subscriptionPlan?.id === 9 ? <div style={smallButtonStyle} onMouseEnter={onButtonEnter} onMouseLeave={onButtonLeave} onClick={goSignup}>Upgrade</div> :
		rolesAdmin.indexOf(globalState.userData?.userrole) > -1 && <div style={smallButtonStyle} onMouseEnter={onButtonEnter} onMouseLeave={onButtonLeave} onClick={goAccountSettings}>View/edit</div>
	)

	// edit user/save user button
	const editSaveButton = (editingUser ?
		<div id='saveButton' style={saveButtonStyle} onMouseEnter={onButtonEnter} onMouseLeave={onButtonLeave} onClick={saveUserData.current}>Save</div> :
		<div style={smallButtonStyle} onMouseEnter={onButtonEnter} onMouseLeave={onButtonLeave} onClick={e=>setEditingUser(true)}>Edit</div>
	)

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	return (
		<div style={menuBackStyle}>
			<div style={{...sectionContainerStyle, borderTop:'none', paddingTop:0}}>
				<div style={{display:'flex'}}>
					<h3 style={headingStyle}>Account</h3>
					{editAccountButton}
				</div>
				<div style={{display:'flex', flexWrap:'wrap'}}>
					<div style={{marginRight:'50px'}}>
						<div style={labelStyle}>Organization</div>
						<h4 style={valueStyle}>{dataRoot.name}</h4>
					</div>
					<div style={{marginRight:'40px'}}>
						<div style={labelStyle}>Subscription</div>
						<div style={{display:'flex'}} onMouseEnter={showInfo} onMouseLeave={hideInfo}>
							<h4 style={{...valueStyle, cursor:'pointer'}} onClick={goAccountSettings}>{subscriptionName}</h4>
							<div id='info' style={infoButtonStyle}>i</div>
						</div>
						<div id='infoBox' style={infoBoxStyle}>
							•&nbsp;&nbsp;Analyses/{subscriptionCycle <= 1 ? 'month' : subscriptionCycle + ' months'}: {analysesTotal}<br/>
							•&nbsp;&nbsp;Max. film length: {maxFilmLength} secs<br/>
							•&nbsp;&nbsp;Max. file size: {maxFilmMb}<br/>
						</div>
						<div style={labelStyle}>Renewal date</div>
						<h4 style={valueStyle}>
							{globalState.userData?.organization?.subscriptionPlan?.id !== 9 ? new Date(resetDate).toLocaleDateString("en-GB", dateOptions) : 'n/a'}
						</h4>
					</div>
				</div>
			</div>
			<div style={sectionContainerStyle}>
				<div style={{display:'flex'}}>
					<h3 style={headingStyle}>User</h3>
					{editSaveButton}
				</div>
				<form>
					<div style={labelStyle}>Name</div>
					<input
						id='name'
						className='fs-input-text'
						style={editingUser ? inputStyleEnabled : inputStyleDisabled}
						tabIndex='1'
						spellCheck='false'
						value={userName}
						onChange={updateFormValue}
						maxLength='100'
						readOnly={!editingUser}
						autoComplete='off'
					/>
					<div style={labelStyle}>Email</div>
					<input
						id='email'
						className='fs-input-text'
						style={editingUser ? inputStyleEnabled : inputStyleDisabled}
						tabIndex='2'
						spellCheck='false'
						value={userEmail}
						onChange={updateFormValue}
						maxLength='150'
						readOnly={!editingUser}
						autoComplete='off'
					/>
					<div style={labelStyle}>Password</div>
					<input
						id='passw'
						className='fs-input-text'
						type='password'
						style={editingUser ? inputStyleEnabled : inputStyleDisabled}
						tabIndex='3'
						spellCheck='false'
						value={userPassword}
						onChange={updateFormValue}
						placeholder={editingUser ? 'Change password' : '••••••••'}
						maxLength='100'
						readOnly={!editingUser}
						autoComplete='new-password'
					/>
				</form>
			</div>
			<div style={sectionContainerStyle}>
				<h3 style={{...headingStyle, marginBottom:'20px'}}>Settings</h3>
				<div style={switchContainerStyle}>
					<Switch
						onChange={toggleTeamAnalyses}
						checked={globalState.userData?.settings?.showTeamAnalyses ? globalState.userData.settings.showTeamAnalyses : false}
						offColor={colors.switchBackground0}
						offHandleColor={colors.switchKnobOff}
						onColor={colors.switchBackground0}
						onHandleColor={colors.switchKnobOn}
						uncheckedIcon={false}
						checkedIcon={false}
						height={22}
						width={40}
						handleDiameter={18}
						activeBoxShadow=''
						id='team-analyses-switch'
					/>
					<h4 style={{color:colors.text, paddingLeft:'12px', lineHeight:'22px', cursor:'pointer'}} onClick={toggleTeamAnalyses}>Team analyses</h4>
				</div>
				<div style={switchContainerStyle}>
					<Switch
						onChange={toggleNewsletter}
						checked={globalState.userData?.permissionType2 ? globalState.userData?.permissionType2 === '1' : false}
						offColor={colors.switchBackground0}
						offHandleColor={colors.switchKnobOff}
						onColor={colors.switchBackground0}
						onHandleColor={colors.switchKnobOn}
						uncheckedIcon={false}
						checkedIcon={false}
						height={22}
						width={40}
						handleDiameter={18}
						activeBoxShadow=''
						id='newsletter-switch'
					/>
					<h4 style={{color:colors.text, paddingLeft:'12px', lineHeight:'22px', cursor:'pointer'}} onClick={toggleNewsletter}>Newsletter</h4>
				</div>
			</div>
			<div style={sectionContainerStyle}>
				<h4 style={{...valueStyle, cursor:'pointer', display:'inline'}} onClick={logout}>Log out</h4>
			</div>
			{alert}
		</div>
	)
}
