import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { GlobalContext } from '../utils/globalState'
import { colors } from '../themes/colors'
import Sidebar from '../components/sidebar/Sidebar'
import AnalysisForm from '../components/analysis-form/AnalysisForm'
import AnalysisProgress from '../components/analysis-progress/AnalysisProgress'
import AnalysisCues from '../components/analysis-tagging/AnalysisCues'

// styles
const containerStyle = {
	textAlign: 'center',
	margin: '0 auto',
}

const backgroundStyle = {
	width: '100%',
	backgroundColor: colors.background0,
	minHeight: '650px'
}

// component function
export default function NewAnalysis() {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [stepInfo, setStepInfo] = useState({ step: 1 })
	const history = useHistory()
	const {guid} = useParams()

	// dynamic styles
	const wrapperStyle = {
		minHeight: 'calc(100vh - 120px)',
	}

	// if we got guid go to step 3 else step 1
	useEffect(() => {
		if (guid) {
			setStepInfo({ step: 3, data: { title: '', guid: guid } })
		} else {
			setStepInfo({ step: 1 })
		}
	}, [guid])

	// go to step 2 - analysis progress
	function goProgress(formData) {
		setStepInfo({ step: 2, data: formData })
	}

	// go to step 3 - cue tagging
	function goCues(guid) {
		history.push('/' + guid)
	}

	// step content logic
	const stepContent = (
		stepInfo.step === 1 ? <AnalysisForm action={goProgress} /> :
		stepInfo.step === 2 ? <AnalysisProgress action={goCues} new={(e)=>setStepInfo({step:1,update:true})} data={stepInfo.data || {}} /> :
		stepInfo.step === 3 && <AnalysisCues data={stepInfo.data || {}} />
	)

	// step content
	const content = (
		// not logged in
		!globalState.loggedIn ?
			null
		:
		// logged in - show step content
		<>
			<div style={backgroundStyle} onDragOver={e=>e.preventDefault()} onDrop={e=>e.preventDefault()}>
				<div style={containerStyle}>
					{stepContent}
				</div>
			</div>
		</>
	)

	return (
		<>
			<Sidebar />
			<div style={wrapperStyle}>
				{content}
			</div>
		</>
	)
}
