import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { APIContext } from '../../../utils/api'
import { GlobalContext } from '../../../utils/globalState'
import { colors } from '../../../themes/colors'
import { industryOptions } from '../../../utils/industryOptions'
import Switch from 'react-switch'
import useLocalStorage from '../../../utils/useLocalStorage'
import SummaryBar from './SummaryBar'
import CheckBox from './CheckBox'
import ScoreCard from './ScoreCard'
import userNormIcon from '../../../assets/images/icon-norm-user.svg'
import industryNormIcon from '../../../assets/images/icon-norm-industry.svg'
import allCompetitorsNormIcon from '../../../assets/images/icon-norm-competitors.svg'
import competitorNormIcon from '../../../assets/images/icon-norm-competitor.svg'
import styles from './styles.module.css'

// component function
export default function ReportSummary(props) {

	const { video } = props
	const { code } = useParams()
	const [norms, setNorms] = useState()
	const [reportSettings, setReportSettings] = useLocalStorage('reportSummarySettings', { showUserNorms:true, showIndustryNorms:false, showAllCompetitorsNorms:false, showCompetitorNorms:false })
	const [addToAverage, setAddToAverage] = useState(false)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [croppedBrandImage, setCroppedBrandImage] = useState()
	const [croppedCommsImage, setCroppedCommsImage] = useState()
	const context = useContext(APIContext)
	const cueTypes = globalState.cueTypes
	const ownVideo = video?.user?.id === globalState.userData?.id
	const duration = (video?.frameCount-3)/video?.frameRate

	// label for video industry code
	const industry = video?.industryCode !== undefined && industryOptions.find(ind => ind.value === video?.industryCode)
	const industryLabel = industry?.label

	// scores
	const clarityScore = Math.round((1-video?.attentionEntropy)*100)
	const brandScore = Math.round(video?.scoreCueTypeCategoryAttention[1])
	const commsScore = Math.round(video?.scoreCueTypeCategoryAttention[2])

	// norms
	const clarityNorms = { // TODO: real clarity norms + competitor norms
		user: reportSettings.showUserNorms && norms?.user?.find(norm => norm.normType === 1).value,
		industry: reportSettings.showIndustryNorms && norms?.industry?.find(norm => norm.normType === 4).value,
		allCompetitors: reportSettings.showAllCompetitorsNorms && norms?.industry?.find(norm => norm.normType === 4).value * .5, // TODO: all competitors’ norms (now faked)
		competitor: reportSettings.showCompetitorNorms && norms?.industry?.find(norm => norm.normType === 4).value * .25 // TODO: competitor norms (now faked)
	}
	const brandNorms = { // TODO: real brand attention norms + competitor norms
		user: reportSettings.showUserNorms && norms?.user?.find(norm => norm.normType === 2).value,
		industry: reportSettings.showIndustryNorms && norms?.industry?.find(norm => norm.normType === 5).value,
		allCompetitors: reportSettings.showAllCompetitorsNorms && norms?.industry?.find(norm => norm.normType === 5).value * .5, // TODO: all competitors’ norms (now faked)
		competitor: reportSettings.showCompetitorNorms && norms?.industry?.find(norm => norm.normType === 5).value * .25 // TODO: competitor norms (now faked)
	}
	const commsNorms = { // TODO: real comms attention norms + competitor norms
		user: reportSettings.showUserNorms && norms?.user?.find(norm => norm.normType === 3).value,
		industry: reportSettings.showIndustryNorms && norms?.industry?.find(norm => norm.normType === 6).value,
		allCompetitors: reportSettings.showAllCompetitorsNorms && norms?.industry?.find(norm => norm.normType === 6).value * .5, // TODO: all competitors’ norms (now faked)
		competitor: reportSettings.showCompetitorNorms && norms?.industry?.find(norm => norm.normType === 6).value * .25 // TODO: competitor norms (now faked)
	}

	// get norms // TODO: different analysis types & competitor norms
	useEffect(() => {
		code ? setNorms(video.norms) :
		context.io.socket.get('/api/v1/video/norms', (normdata, res) => { // get user’s own default norms (analysisType 1) separately if not shared report
			if (res.statusCode === 200) {
				const industryNormsFiltered = normdata.industry.filter(norm => norm.industryCode === video.industryCode) // only norms w the video industryCode
				const normsFiltered = { user:normdata.user, industry:industryNormsFiltered }
				setNorms(normsFiltered)
			} else {
				// TODO: error handling
			}
		}) 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.io.socket])

	// calculate score at view-time durations
	function viewTimeScores(secs) {
		const lastFrame = Math.round(video.frameRate * secs)
		const frames = video.frames.slice(0, lastFrame)
		return {
			clarity: Math.round((1 - (frames.reduce((a, b) => a + b.attentionEntropy, 0) / frames.length)) * 100),
			brand: Math.round(video.frames[lastFrame].scoreCueTypeCategoryAttention[1]),
			comms: Math.round(video.frames[lastFrame].scoreCueTypeCategoryAttention[2])
		}
	}

	// toggle add to average
	function toggleAddToAverage() {
		setAddToAverage(!addToAverage)

		// TODO: endpoint call to add choice to settings for individual video
	}

	// toggle show user norms
	function toggleShowUserNorms() {
		setReportSettings({...reportSettings, showUserNorms:!reportSettings.showUserNorms})
	}

	// toggle show industry norms
	function toggleShowIndustryNorms() {
		setReportSettings({...reportSettings, showIndustryNorms:!reportSettings.showIndustryNorms})
	}

	// toggle show all competitors norms
	function toggleShowAllCompetitorsNorms() {
		setReportSettings({...reportSettings, showAllCompetitorsNorms:!reportSettings.showAllCompetitorsNorms})
	}

	// toggle show specific competitor norms
	function toggleShowCompetitorNorms() {
		setReportSettings({...reportSettings, showCompetitorNorms:!reportSettings.showCompetitorNorms})
	}

	// competitor selected
	function competitorSelected() {
		console.log('COMPETITOR SELECTED') // TODO: handle competitor selection
	}
	
	// get image of best brand asset
	function getBestImage(asset, type) {
		const assetObjects = video.objects.filter(obj => obj.segment === asset.id)
		const assetFirstObject = assetObjects.length > 15 ? assetObjects[15] : assetObjects[assetObjects.length-1] // generate thumb a few frames into the segment if possible to compensate for fades
		const assetStartFrame = assetFirstObject.frameNo
		const chunk = Math.floor(assetStartFrame/30)
		const col = assetStartFrame % 5
		const row = Math.floor(assetStartFrame % 30 / 5)
		const image = new Image()
		image.crossOrigin = 'Anonymous'
		image.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000" + chunk).slice(-7) + '.jpg'
		image.onload = e=>cropImage(image, assetFirstObject.x, assetFirstObject.y, assetFirstObject.w, assetFirstObject.h, col, row, type)
	}

	// crop image to bounds of first object in best segment
	function cropImage(image, x, y, objwidth, objheight, col, row, type) {
		const cropCanvas = document.createElement('canvas')
		const ctx = cropCanvas.getContext('2d')
		ctx.imageSmoothingEnabled = false
		const w = image.width/5  // chunk frame width
		const h = image.height/6 // chunk frame height
		cropCanvas.width = objwidth * w * window.devicePixelRatio
		cropCanvas.height = objheight * h * window.devicePixelRatio
		ctx.drawImage(image, col * w + x * w, row * h + y * h, objwidth * w, objheight * h, 0, 0, objwidth * w * window.devicePixelRatio, objheight * h * window.devicePixelRatio)
		type === 'brand' && setCroppedBrandImage(cropCanvas.toDataURL('image/jpeg'))
		type === 'comms' && setCroppedCommsImage(cropCanvas.toDataURL('image/jpeg'))
	}

	// asset & asset type defaults for score cards
	let bestBrandAsset = {scoreAttention: -1}
	let bestBrandAssetType = ''
	let bestBrandAssetTypeScore = -1
	let bestCommsAsset = {scoreAttention: -1}
	let bestCommsAssetType = ''
	let bestCommsAssetTypeScore = -1

	// get tagged segments
	const taggedBrandSegments = video.segments.filter(s => s.cueType !== null && cueTypes.find(cueType => cueType.id === s.cueType)?.cueTypeCategory === 1)
	const taggedCommsSegments = video.segments.filter(s => s.cueType !== null && cueTypes.find(cueType => cueType.id === s.cueType)?.cueTypeCategory === 2)

	if (taggedBrandSegments.length) {
		// get best performing brand assets
		bestBrandAsset = taggedBrandSegments.reduce((a, b) => (a.scoreAttention > b.scoreAttention) ? a : b)

		// get best performing brand asset types
		const usedBrandCueTypes = [...new Set(taggedBrandSegments.map(seg => seg.cueType))];
		const brandCueScores = []
		usedBrandCueTypes.map((cueType, i) => {
			const brandCueSum = taggedBrandSegments.filter(seg => seg.cueType === usedBrandCueTypes[i]).reduce((a, b) => {return a + b.scoreAttention},0)
			brandCueScores.push({cueType: cueType, score: brandCueSum})
			return 0
		})
		const bestBrandAssetTypeId = brandCueScores.reduce((a, b) => (a.score > b.score) ? a : b).cueType
		bestBrandAssetType = cueTypes.find(cue => cue.id === bestBrandAssetTypeId).name

		// get best performing asset type scores (average of scores in segments with best performing asset type)
		const segmentsInBestBrandAssetType = taggedBrandSegments.filter(seg => seg.cueType === bestBrandAssetTypeId)
		bestBrandAssetTypeScore = segmentsInBestBrandAssetType.reduce((a, b) => {return a + b.scoreAttention},0)

		getBestImage(bestBrandAsset, 'brand')
	}

	if (taggedCommsSegments.length) {
		// get best performing comms assets
		bestCommsAsset = taggedCommsSegments.reduce((a, b) => (a.scoreAttention > b.scoreAttention) ? a : b)

		// get best performing comms asset types
		const usedCommsCueTypes = [...new Set(taggedCommsSegments.map(seg => seg.cueType))];
		const commsCueScores = []
		usedCommsCueTypes.map((cueType, i) => {
			const commsCueSum = taggedCommsSegments.filter(seg => seg.cueType === usedCommsCueTypes[i]).reduce((a, b) => {return a + b.scoreAttention},0)
			commsCueScores.push({cueType: cueType, score: commsCueSum})
			return 0
		})
		const bestCommsAssetTypeId = commsCueScores.reduce((a, b) => (a.score > b.score) ? a : b).cueType
		bestCommsAssetType = cueTypes.find(cue => cue.id === bestCommsAssetTypeId).name

		// get best performing asset type scores (average of scores in segments with best performing asset type)
		const segmentsInBestCommsAssetType = taggedCommsSegments.filter(seg => seg.cueType === bestCommsAssetTypeId)
		bestCommsAssetTypeScore = segmentsInBestCommsAssetType.reduce((a, b) => {return a + b.scoreAttention},0)

		getBestImage(bestCommsAsset, 'comms')
	}

	// subheading text based on still or video
	const subHeadingText = video?.analysisType === 3 ? 'Scores based on a minimum of 3 seconds ad attention' : 'Scores based on viewers watching the full ad'

	// toggle
	const averageToggle = (globalState.loggedIn && !code && ownVideo &&
		<div className={styles.switchContainer}>
			<div onClick={toggleAddToAverage}>Add to my {industryLabel} average</div>
			<Switch
				onChange={toggleAddToAverage}
				checked={addToAverage}
				offColor={colors.switchBackground0}
				offHandleColor={colors.switchKnobOff}
				onColor={colors.switchBackground0}
				onHandleColor={colors.switchKnobOn}
				uncheckedIcon={false}
				checkedIcon={false}
				height={16}
				width={30}
				handleDiameter={16}
				activeBoxShadow=''
				id='add-to-industry-switch'
			/>
		</div>
	)

	// competitor select
	const competitorMenu = (
		<div className={styles.selectWrapper}>
			<select id='select-competitor' className={styles.select} onClick={e=>e.stopPropagation()} onChange={e=>competitorSelected(e.target.value)} title="Select competitor"> {/* TODO: values from backend - not hard-coded */}
				<option value="">Løgismose</option>
				<option value="">Thise</option>
			</select>
		</div>
	)

	// view-time score cards (if video)
	const viewTimeScoreCards = (video?.analysisType !== 3 &&
		<>
			<h4 className={styles.cardGroupHeading}>Ad performance at different view-time durations</h4>
			<div className={styles.scoreCardsWrapper}>
				{duration >= 2 && <ScoreCard title='2 seconds' scores={viewTimeScores(2)} />}
				{duration >= 6 && <ScoreCard title='6 seconds' scores={viewTimeScores(6)} />}
				{duration >= 15 && <ScoreCard title='15 seconds' scores={viewTimeScores(15)} />}
				<ScoreCard title='Full ad watched' scores={{clarity:clarityScore, brand:brandScore, comms:commsScore}} />
			</div>
		</>
	)
	
	// score cards for best performing assets
	const bestPerformingAssets = (
		<>
			<h4 className={styles.cardGroupHeading}>Best performing asset & asset type</h4>
			<div className={styles.scoreCardsWrapper + ' ' + styles.scoreCardsBest}>
				<ScoreCard type='brand' title='Brand asset' scores={{brand: Math.round(bestBrandAsset.scoreAttention)}} label={bestBrandAsset.name} image={croppedBrandImage} />
				<ScoreCard type='brand' title='Brand asset type' scores={{brand: Math.round(bestBrandAssetTypeScore)}} label={bestBrandAssetType} />
				<ScoreCard type='comms' title='Communication asset' scores={{comms: Math.round(bestCommsAsset.scoreAttention)}} label={bestCommsAsset.name} image={croppedCommsImage}/>
				<ScoreCard type='comms' title='Communication asset type' scores={{comms: Math.round(bestCommsAssetTypeScore)}} label={bestCommsAssetType} />
			</div>
		</>
	)

	return (
		<div className={styles.background}>
			<div className={styles.header}>
				<div className={styles.heading}>
					Ad performance summary
					<div className={styles.subHeading}>{subHeadingText}</div>
				</div>
				<div className={styles.switchesContainer}>
					{averageToggle}
				</div>
			</div>
			<div className={styles.barsWrapper}>
				<SummaryBar type={'clarity'} label={'Visual Clarity'} score={clarityScore} norms={clarityNorms} />
				<SummaryBar type={'brand'} label={'Brand Attention'} score={brandScore} norms={brandNorms} />
				<SummaryBar type={'comms'} label={'Comm. Attention'} score={commsScore} norms={commsNorms} />
			</div>
			<div className={styles.normControlsWrapper}>
				<div className={styles.normControl} onClick={toggleShowUserNorms} title={ownVideo ? 'Your ' + industryLabel + ' average' : 'User’s ' + industryLabel + ' average'}>
					<CheckBox checked={reportSettings.showUserNorms} />
					{ownVideo ? 'My average' : 'User’s average'}
					<img src={userNormIcon} alt="" />
				</div>
				<div className={styles.normControl} onClick={toggleShowIndustryNorms} title={industryLabel + ' - industry average'}>
					<CheckBox checked={reportSettings.showIndustryNorms} />
					Industry average
					<img src={industryNormIcon} alt="" />
				</div>
				<div className={styles.normControl} onClick={toggleShowAllCompetitorsNorms} title={industryLabel + ' - all competitors’ average'}>
					<CheckBox checked={reportSettings.showAllCompetitorsNorms} />
					All competitors’ average
					<img src={allCompetitorsNormIcon} alt="" />
				</div>
				<div className={styles.normControl} onClick={toggleShowCompetitorNorms} title={industryLabel + ' - competitor average'}>
					<CheckBox checked={reportSettings.showCompetitorNorms} />
					{competitorMenu} average
					<img src={competitorNormIcon} alt="" />
				</div>
			</div>
			{viewTimeScoreCards}
			{bestPerformingAssets}
		</div>
	)
}
