import React from 'react'
import styles from './styles.module.css'

// component function
export default function SummaryBar(props) {

	const { type, label, score, norms } = props
	const barColor = type === 'clarity' ? styles.colorClarity : type === 'brand' ? styles.colorBrand : styles.colorComms

	// dynamic styles
	const barStyle = {
		width: score + (score < 49 ? 1 : 0) + '%'
	}

	const scoreboxStyle = {
		left: score + '%'
	}

	const userNormsStyle = {
		left: norms.user + '%'
	}

	const industryNormsStyle = {
		left: norms.industry + '%'
	}

	const allCompetitorsNormsStyle = {
		left: norms.allCompetitors + '%'
	}

	const competitorNormsStyle = {
		left: norms.competitor + '%'
	}

	const userNorm = norms.user !== false && norms.user !== undefined && !isNaN(norms.user) && <div style={userNormsStyle} className={styles.norm + ' ' + styles.userNorm}>{Math.round(norms.user)}</div>
	const industryNorm = norms.industry !== false && norms.industry !== undefined && !isNaN(norms.industry) && <div style={industryNormsStyle} className={styles.norm + ' ' + styles.industryNorm}>{Math.round(norms.industry)}</div>
	const allCompetitorsNorm = norms.allCompetitors !== false && norms.allCompetitors !== undefined && !isNaN(norms.allCompetitors) && <div style={allCompetitorsNormsStyle} className={styles.norm + ' ' + styles.allCompetitorsNorm}>{Math.round(norms.allCompetitors)}</div>
	const competitorNorm = norms.competitor !== false && norms.competitor !== undefined && !isNaN(norms.competitor) && <div style={competitorNormsStyle} className={styles.norm + ' ' + styles.competitorNorm}>{Math.round(norms.competitor)}</div>

	return (
		<div className={styles.wrapper}>
			<div className={styles.label}>{label}</div>
			<div className={styles.barWrapper}>
				<div className={styles.barBack}>
					<div style={barStyle} className={styles.bar + ' ' + barColor} />
				</div>
				<div className={styles.scoreBoxWrapper}>
					<div style={scoreboxStyle} className={styles.scoreBox + ' ' + barColor}>{score}</div>
					{userNorm}
					{industryNorm}
					{allCompetitorsNorm}
					{competitorNorm}
				</div>
			</div>
			<div className={styles.smallLabel}>100</div>
		</div>
	)
}
