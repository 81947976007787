import React, { useRef } from 'react'
import styles from './styles.module.css'

// component function
export default function InfoBubble(props) {

	const { info, pos='right', size, dist, move, inverse=false } = props
	const infoRef = useRef()

	function showInfo() {
		infoRef.current.style.opacity = 1;
	}

	function hideInfo() {
		infoRef.current.style.opacity = 0;
	}

	const textBoxStyle = {
		maxWidth: size,
		top: (pos === 'top' ? 'auto' : pos === 'bottom' ? dist + 20 : pos === 'left' || pos === 'right' ? move + 4 : 'auto'),
		bottom: (pos === 'top' ? dist + 20 : 'auto'),
		left: (pos === 'top' || pos === 'bottom' ? move + 4 : pos === 'right' ? dist + 20 : 'auto'),
		right: (pos === 'left' ? dist + 20 : 'auto')
	}

	return info && (
		<div className={styles.infoGroup}>
			<div className={styles.infoWrapper} onMouseOver={showInfo} onMouseOut={hideInfo}>
				<div className={styles.info + (inverse ? ' ' + styles.infoInverse : '')}>?</div>
			</div>
			<div className={styles.infoText} style={textBoxStyle} ref={infoRef}>{info}</div>
		</div>
	)
}
