import React from 'react'

// styles
const containerStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	minWidth: '748px',
	maxWidth: '1110px',
	height: 'calc(100vh - 323px)',
	margin: '0 auto',
	padding: '93px 10px 110px',
	textAlign: 'center'
}

// component function
export default function NotFound() {
	return (
		<div style={containerStyle}>
			<h2>404 - Not found</h2>
		</div>
	)
}
