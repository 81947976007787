import React, { useState, useEffect, useRef } from 'react'
import { colors } from '../../themes/colors'

// styles
const buttonStyle = {
	minWidth: '120px',
	padding: '6px 24px 5px',
	fontSize: '14px',
	letterSpacing: '.2px'
}

const messageContainerStyle = {
	width: '500px',
	backgroundColor: colors.background1,
	borderRadius: '15px',
	boxSizing: 'border-box',
	padding: '39px 40px 43px',
	textAlign: 'center',
	boxShadow: '0px 0px 70px 0px ' + colors.alertShadow
}

// component function
export default function Alert(props) {

	const { type, title, message, cancelLabel='Cancel', actionLabel='OK', waitingLabel, useReturnKey=true, action } = props
	const [opacity, setOpacity] = useState(0)
	const [waiting, setWaiting] = useState(false)
	const titleWithBreaks = title?.split('\n').map((item, i) => { return <p key={i}>{item}</p> })
	const messageWithBreaks = message?.split('\n').map((item, i) => { return <div key={i} style={{marginTop:'13px',userSelect:'text',WebkitUserSelect:'text'}}>{item}</div> })
	const titleDiv = title && <h4 style={{marginBottom:'10px',color:colors.text,fontFamily:message ? 'Greycliff demibold' : 'Greycliff'}}>{titleWithBreaks}</h4>
	const messageDiv = message && <div style={{userSelect:'text',WebkitUserSelect:'text',fontSize:'14px',lineHeight:'20px',color:colors.text}}>{messageWithBreaks}</div>
	const buttonCancel = type === 'confirm' && !waiting && <button style={{...buttonStyle, backgroundColor:colors.cancelButtonBack, marginRight:'20px'}} tabIndex="-1" id="alert-btn-cancel" className="fs-button" disabled={waiting} onClick={e=>performAction(e,false)}>{cancelLabel.toUpperCase()}</button>
	const buttonOk = <button style={buttonStyle} tabIndex="-1" id="alert-btn-ok" className="fs-button" disabled={waiting} onClick={e=>performAction(e,true)}>{waiting ? waitingLabel ? waitingLabel.toUpperCase() : actionLabel.toUpperCase() : actionLabel.toUpperCase()}</button>
	const timeoutID = useRef()
	const waitingFlagRef = useRef(false)

	// dynamic styles
	const overlayStyle = {
		position: 'fixed',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100%',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		opacity: opacity,
		transition: 'opacity .4s',
		backgroundColor: colors.backgroundOverlay,
		zIndex: '9999'
	}

	// fade in & set keypress listener
	useEffect(() => {
		timeoutID.current = setTimeout(function() { // delay - otherwise no opacity transition
			setOpacity(1)
		}, 200)
		window.addEventListener('keydown', keyPressed)
		// cleanup on unmount
		return () => { window.removeEventListener('keydown', keyPressed) }
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// handle keypress events
	function keyPressed(e) {
		e.preventDefault()
		if (e.keyCode === 27 && !waitingFlagRef.current) { // escape
			clearTimeout(timeoutID.current)
			action(false)
		} else if (e.keyCode === 13 && useReturnKey && !waitingFlagRef.current) { // enter
			clearTimeout(timeoutID.current)
			action(true)
			setWaiting(true)
		}
	}

	// handle button click
	function performAction(e,b) {
		e.nativeEvent.stopImmediatePropagation()
		setWaiting(true)
		waitingFlagRef.current = true
		action(b)
	}

	return (
		<div id="overlay" style={overlayStyle}>
			<div style={messageContainerStyle}>
				{titleDiv}
				{messageDiv}
				<div style={{marginTop:'30px'}}>{buttonCancel}{buttonOk}</div>
			</div>
		</div>
	)
}
