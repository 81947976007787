import React from 'react'
import { useHistory } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { colors } from '../../../themes/colors'
import styles from './styles.module.css'

// component function
export default function LatestAnalysis(props) {

	const { analyses } = props
	const history = useHistory()
	const finishedAnalyses = analyses?.filter(a => a.frontendState?.status === 'finished')
	const item = finishedAnalyses?.sort((a, b) => b.updatedAt - a.updatedAt)[0] // latest FINISHED analysis
	const imgSrc = item && process.env.REACT_APP_GCS_BUCKET_URL + '/' + item.guid + '/thumb.jpg'

	// analysis creation date
	const dateOptions = { year:'numeric', month:'short', day:'numeric', hour:'numeric', minute:'numeric' }
	const createdDate = item && new Date(item.createdAt).toLocaleDateString("en-GB", dateOptions)

	// dynamic styles
	const bgImageStyle = {
		backgroundImage:'url(' + imgSrc + ')', 
		backgroundSize: (item?.height/item?.width) <= .75 ? '192%' : 'cover' // scale up to hide black letterbox bars
	}

	// go to report
	function goReport() {
		history.push('/report/' + item.guid)
	}

	const latestContent = (item ?
		<>
		<div style={bgImageStyle} className={styles.thumb}>
			<div className={styles.thumbText}>
				<div className={styles.brand}>{item?.brand?.name}</div>
				<div className={styles.title}>{item?.title}</div>
				<div className={styles.date}>{createdDate}</div>
			</div>
		</div>
		<div className={styles.scoreText}>
			<div className={styles.labelWrapper}>
				<div className={styles.scoreLabel}>Latest analysis</div>
				<div className={styles.cta}>See report &gt;</div>
			</div>
			<div className={styles.scoreWrapper}>
				<div className={styles.scoreBox + ' ' + styles.scoreClarity}>{item && Math.round((1-item.attentionEntropy) * 100)}</div>
				<div className={styles.scoreBox + ' ' + styles.scoreBrand}>{item && Math.round(item.scoreCueTypeCategoryAttention[1])}</div>
				<div className={styles.scoreBox + ' ' + styles.scoreComms}>{item && Math.round(item.scoreCueTypeCategoryAttention[2])}</div>
			</div>
		</div>
		</>
		:
		finishedAnalyses?.length === 0 ?
		<>
			<div className={styles.noAnalysesHeading}>Latest analysis</div>
			<div className={styles.noAnalyses}>No analyses completed</div>
		</>
		:
		<div className={styles.loader}>
			<BeatLoader color={colors.background3} />
		</div>
	)

	return (
		<div className={styles.container} style={item && {cursor:'pointer'}} onClick={item && goReport}>
			{latestContent}
		</div>
	)
}
