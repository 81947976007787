import React, { useState, useEffect, useRef } from 'react'
import Switch from 'react-switch'
import { colors } from '../../../../themes/colors'
import styles from './styles.module.css'

// component function
export default function DetailsGraph(props) {

	const { video, frameScores, type, reportSettings, setReportSettings } = props

	const [settingsVisible, setSettingsVisible] = useState(false)
	const [graphCanvasWidth, setGraphCanvasWidth] = useState(810 * window.devicePixelRatio)
	const graphCanvasRef = useRef()
	const graphCtxRef = useRef()
	const showClarityRef = useRef()
	const showBrandRef = useRef()
	const showCommsRef = useRef()
	const settingsButtonRef = useRef()
	const settingsContainerRef = useRef()
	const graphHeight = 111
	const graphCanvasHeight = graphHeight * window.devicePixelRatio
	const duration = (video?.frameCount-3)/video?.frameRate

	// set refs (for js functions that don’t get state)
	showClarityRef.current = reportSettings.showClarity
	showBrandRef.current = reportSettings.showBrand
	showCommsRef.current = reportSettings.showComms

	// canvas setup & resize listener
	useEffect(() => {
		graphCtxRef.current = graphCanvasRef.current.getContext('2d')
		window.removeEventListener('resize', handleResize)
		window.addEventListener('resize', handleResize)
		window.addEventListener('keydown', keyPressed)
		const settingsCon = settingsContainerRef.current
		const settingsBtn = settingsButtonRef.current
		settingsCon.addEventListener('mousedown', ignoreHide)
		settingsBtn.addEventListener('mousedown', ignoreHide)
		// cleanup on unmount
 		return () => {
			window.removeEventListener('resize', handleResize)
			window.removeEventListener('keydown', keyPressed)
			settingsCon.removeEventListener('mousedown', ignoreHide)
			settingsBtn.addEventListener('mousedown', ignoreHide)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	// report settings changes
	useEffect(() => {
		handleResize()
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[reportSettings])

	// handle graph width/canvas resolution on resize
	function handleResize() {
		setGraphCanvasWidth(graphCanvasRef.current.clientWidth * window.devicePixelRatio)
		requestAnimationFrame(drawGraph)
	}

	// hide settings on esc
	function keyPressed(e) {
		e.keyCode === 27 && hideSettings(e)
	}

	// initial draw graph
	graphCanvasRef?.current?.clientWidth && drawGraph()

	// draw graph below video
	function drawGraph() {
		const graphCurrentWidth = graphCanvasRef.current.clientWidth * window.devicePixelRatio
		const ctx = graphCtxRef.current
		ctx.lineWidth = 1.25 * window.devicePixelRatio
		ctx.clearRect(0, 0, graphCurrentWidth, graphCanvasHeight)
		const step = (graphCurrentWidth+1)/(video.frameCount-3)
		let mapArray

		// clarity
		if (showClarityRef.current) {
			ctx.moveTo(0, graphCanvasHeight)
			ctx.beginPath()
			graphCtxRef.current.strokeStyle = colors.clarity
			mapArray = type === 'average' ? frameScores.averageClarity : frameScores.frameClarity // TODO: get AVERAGE .attentionEntropy (reverse) directly from frame
			mapArray.map((score, i) => {
				ctx.lineTo(i * step, graphCanvasHeight + 1 - score * graphCanvasHeight)
				return 0
			})
			ctx.stroke()
		}

		// brand attention
		if (showBrandRef.current) {
			ctx.moveTo(0, graphCanvasHeight)
			ctx.beginPath()
			graphCtxRef.current.strokeStyle = colors.brand
			video?.frames.map((frame, i) => {
				const score = type === 'average' ? frame.scoreCueTypeCategoryAttention && frame.scoreCueTypeCategoryAttention[1] : frame.thisFrameScoreCueTypeCategoryAttention && frame.thisFrameScoreCueTypeCategoryAttention[1]
				ctx.lineTo(i * step, graphCanvasHeight + 1 - score/100 * graphCanvasHeight)
				return 0
			})
			ctx.stroke()
		}

		// comms attention
		if (showCommsRef.current) {
			ctx.moveTo(0, graphCanvasHeight)
			ctx.beginPath()
			graphCtxRef.current.strokeStyle = colors.comms
			video?.frames.map((frame, i) => {
				const score = type === 'average' ? frame.scoreCueTypeCategoryAttention && frame.scoreCueTypeCategoryAttention[2] : frame.thisFrameScoreCueTypeCategoryAttention && frame.thisFrameScoreCueTypeCategoryAttention[2]
				ctx.lineTo(i * step, graphCanvasHeight + 1 - score/100 * graphCanvasHeight)
				return 0
			})
			ctx.stroke()
		}
	}

	// toggle settings visibility
	function toggleSettings() {
		if (!settingsVisible) {
			document.addEventListener('mousedown', hideSettings)
		}
		setSettingsVisible(!settingsVisible)
	}

	// close settings (on click outside settings)
	function hideSettings(e) {
		if (!e.target.classList.contains('react-switch-handle')) {
			document.removeEventListener('mousedown', hideSettings)
			setSettingsVisible(false)
		}
	}

	// ignore hide settings if click is inside settings
	function ignoreHide(e) {
		if (!e.target.classList.contains('react-switch-handle')) {
			e.stopPropagation()
		}
	}

	// toggle show clarity
	function toggleShowClarity() {
		setReportSettings({...reportSettings, showClarity:!reportSettings.showClarity})
	}

	// toggle show brand attention
	function toggleShowBrand() {
		setReportSettings({...reportSettings, showBrand:!reportSettings.showBrand})
	}

	// toggle show comms attention
	function toggleShowComms() {
		setReportSettings({...reportSettings, showComms:!reportSettings.showComms})
	}

	const secs = [2, 6, 15, duration] // time marker definitions
	let scores = []
	let positions = []

	// build score/pos array per sec per metric for scoreboxes
	if (type === 'average') {
		secs.map((s,i) => {
			const clarityScore = s < duration ? Math.round(frameScores.averageClarity[Math.round(video.frameRate * s)] * 100) : Math.round(frameScores.averageClarity[video.frameCount-3]*100) // TODO: get AVERAGE .attentionEntropy (reverse) directly from frame
			const brandScore = s < duration ? Math.round(video.frames[Math.round(video.frameRate * s)].scoreCueTypeCategoryAttention[1]) : Math.round(video?.scoreCueTypeCategoryAttention[1])
			const commsScore = s < duration ? Math.round(video.frames[Math.round(video.frameRate * s)].scoreCueTypeCategoryAttention[2]) : Math.round(video?.scoreCueTypeCategoryAttention[2])
			scores.push({
				clarity: clarityScore,
				brand: brandScore,
				comms: commsScore
			})
			positions.push([
				{metric: 'clarity', pos: Math.round(2 + graphHeight - graphHeight/100 * clarityScore) - .00002},	// subtract small value to prioritize sort order if equal
				{metric: 'brand', pos: Math.round(2 + graphHeight - graphHeight/100 * brandScore) - .00001},		// subtract small value to prioritize sort order if equal
				{metric: 'comms', pos: Math.round(2 + graphHeight - graphHeight/100 * commsScore)}
			])
			// remove objects with hidden metrics
			positions[i] = positions[i].filter(p => (p.metric === 'clarity' && showClarityRef.current) || (p.metric === 'brand' && showBrandRef.current) || (p.metric === 'comms' && showCommsRef.current))
			// order objects by score
			positions[i].sort((a, b) => (a.pos > b.pos) ? 1 : -1)
			return 0
		})

		// keep distance between scoreboxes
		positions.map(p => {
			if (p.length === 3) { // all metrics visible
				if (p[1].pos - p[0].pos < 15 && p[2].pos - p[1].pos < 15) {
					p[0].pos = p[1].pos - 15
					p[2].pos = p[1].pos + 15
				} else if (p[1].pos - p[0].pos < 15) {
					p[0].pos -= (15 - (p[1].pos - p[0].pos)) / 2
					p[1].pos = p[0].pos + 15
				} else if (p[2].pos - p[1].pos < 15) {
					p[1].pos -= (15 - (p[2].pos - p[1].pos)) / 2
					p[2].pos = p[1].pos + 15
				}
			} else if (p.length === 2) { // two metrics visible
				if (p[1].pos - p[0].pos < 15) {
					p[0].pos -= (15 - (p[1].pos - p[0].pos)) / 2
					p[1].pos = p[0].pos + 15
				}
			}
			// check spacing after adjustments (if new overlap occurred)
			let prev = null
			let moved = 0
			p.map(obj => {
				if (prev !== null) {
					if (obj.pos - prev.pos < 15) {
						moved += 15 - (obj.pos - prev.pos)
						obj.pos = prev.pos + 15
					}
				}
				prev = obj
				return 0
			})
			p.map(obj => {
				obj.pos -= moved/2
				return 0
			})
			// check graph top is not exceeded
			prev = null
			p.map(obj => {
				if (prev !== null) {
					if (obj.pos - prev.pos < 15) {
						obj.pos = prev.pos + 15
					}
				} else {
					if (obj.pos < 8) {
						obj.pos = 8
					}
				}
				prev = obj
				return 0
			})
			// check graph bottom is not exceeded
			prev = null
			p.reverse().map(obj => {
				if (prev !== null) {
					if (prev.pos - obj.pos < 15) {
						obj.pos = prev.pos - 15
					}
				} else {
					if (obj.pos > graphHeight - 5) {
						obj.pos = graphHeight - 5
					}
				}
				prev = obj
				return 0
			})
			return 0
		})
	}

	// clarity toggle
	const clarityToggle = (
		<div className={styles.switchContainer}>
			<div className={styles.switchLabel} onClick={toggleShowClarity}>Visual Clarity</div>
			<Switch
				onChange={toggleShowClarity}
				checked={reportSettings.showClarity}
				offColor={colors.switchBackground0}
				offHandleColor={colors.switchKnobOff}
				onColor={colors.switchBackground0}
				onHandleColor={colors.switchKnobOn}
				uncheckedIcon={false}
				checkedIcon={false}
				height={16}
				width={30}
				handleDiameter={16}
				activeBoxShadow=''
				id={'clarity-'+type+'-switch'}
			/>
		</div>
	)

	// brand attention toggle
	const brandToggle = (
		<div className={styles.switchContainer}>
			<div className={styles.switchLabel} onClick={toggleShowBrand}>Brand Attention</div>
			<Switch
				onChange={toggleShowBrand}
				checked={reportSettings.showBrand}
				offColor={colors.switchBackground0}
				offHandleColor={colors.switchKnobOff}
				onColor={colors.switchBackground0}
				onHandleColor={colors.switchKnobOn}
				uncheckedIcon={false}
				checkedIcon={false}
				height={16}
				width={30}
				handleDiameter={16}
				activeBoxShadow=''
				id={'brand-'+type+'-switch'}
			/>
		</div>
	)

	// comms attention toggle
	const commsToggle = (
		<div className={styles.switchContainer}>
			<div className={styles.switchLabel} onClick={toggleShowComms}>Comm. Attention</div>
			<Switch
				onChange={toggleShowComms}
				checked={reportSettings.showComms}
				offColor={colors.switchBackground0}
				offHandleColor={colors.switchKnobOff}
				onColor={colors.switchBackground0}
				onHandleColor={colors.switchKnobOn}
				uncheckedIcon={false}
				checkedIcon={false}
				height={16}
				width={30}
				handleDiameter={16}
				activeBoxShadow=''
				id={'comms-'+type+'-switch'}
			/>
		</div>
	)

	const averageMarkers = (type === 'average' &&
		<div className={styles.averageWrapper}>
			{duration >= secs[0]+1 && <div style={{left: secs[0]/duration * 100 + '%'}} className={styles.averageMarker}>
				<div className={styles.averageSecsLabel}>{secs[0]}</div>
				{showClarityRef.current && <div style={{top: positions[0].find(p => p.metric === 'clarity').pos}} className={styles.scoreBox + ' ' + styles.colorClarity}>{scores[0].clarity}</div>}
				{showBrandRef.current && <div style={{top: positions[0].find(p => p.metric === 'brand').pos}} className={styles.scoreBox + ' ' + styles.colorBrand}>{scores[0].brand}</div>}
				{showCommsRef.current && <div style={{top: positions[0].find(p => p.metric === 'comms').pos}} className={styles.scoreBox + ' ' + styles.colorComms}>{scores[0].comms}</div>}
			</div>}
			{duration >= secs[1]+1 && <div style={{left: secs[1]/duration * 100 + '%'}} className={styles.averageMarker}>
				<div className={styles.averageSecsLabel}>{secs[1]}</div>
				{showClarityRef.current && <div style={{top: positions[1].find(p => p.metric === 'clarity').pos}} className={styles.scoreBox + ' ' + styles.colorClarity}>{scores[1].clarity}</div>}
				{showBrandRef.current && <div style={{top: positions[1].find(p => p.metric === 'brand').pos}} className={styles.scoreBox + ' ' + styles.colorBrand}>{scores[1].brand}</div>}
				{showCommsRef.current && <div style={{top: positions[1].find(p => p.metric === 'comms').pos}} className={styles.scoreBox + ' ' + styles.colorComms}>{scores[1].comms}</div>}
			</div>}
			{duration >= secs[2]+1 && <div style={{left: secs[2]/duration * 100 + '%'}} className={styles.averageMarker}>
				<div className={styles.averageSecsLabel}>{secs[2]}</div>
				{showClarityRef.current && <div style={{top: positions[2].find(p => p.metric === 'clarity').pos}} className={styles.scoreBox + ' ' + styles.colorClarity}>{scores[2].clarity}</div>}
				{showBrandRef.current && <div style={{top: positions[2].find(p => p.metric === 'brand').pos}} className={styles.scoreBox + ' ' + styles.colorBrand}>{scores[2].brand}</div>}
				{showCommsRef.current && <div style={{top: positions[2].find(p => p.metric === 'comms').pos}} className={styles.scoreBox + ' ' + styles.colorComms}>{scores[2].comms}</div>}
			</div>}
			<div style={{marginLeft: 'auto'}} className={styles.averageMarker}>
				<div className={styles.scaleLabel}>&nbsp;</div>
				{showClarityRef.current && <div style={{top: positions[3].find(p => p.metric === 'clarity').pos}} className={styles.scoreBox + ' ' + styles.colorClarity}>{scores[3].clarity}</div>}
				{showBrandRef.current && <div style={{top: positions[3].find(p => p.metric === 'brand').pos}} className={styles.scoreBox + ' ' + styles.colorBrand}>{scores[3].brand}</div>}
				{showCommsRef.current && <div style={{top: positions[3].find(p => p.metric === 'comms').pos}} className={styles.scoreBox + ' ' + styles.colorComms}>{scores[3].comms}</div>}
			</div>
		</div>
	)

	const settings = (
		<div ref={settingsContainerRef} className={styles.settingsContainer + ' ' + (settingsVisible && styles.showSettings)}>
			{clarityToggle}
			{brandToggle}
			{commsToggle}
		</div>
	)

	return (
		<div className={styles.graphWrapper}>
			<div style={{marginLeft: 'auto'}} className={styles.averageSecsLabel}>{Math.round(video.frameCount/video.frameRate)}</div>
			<div className={styles.graphScale}>
				<div className={styles.scaleLabel}>100</div>
				<div className={styles.scaleLine} />
			</div>
			<div className={styles.graphScale}>
				<div className={styles.scaleLabel}>50</div>
				<div className={styles.scaleLine + ' ' + styles.midLine} />
			</div>
			<div className={styles.graphScale}>
				<div className={styles.scaleLabel}>0</div>
				<div className={styles.scaleLine} />
			</div>
			{averageMarkers}
			<div className={styles.canvasWrapper}>
				<canvas className={styles.canvas} ref={graphCanvasRef} width={graphCanvasWidth} height={graphCanvasHeight} />
			</div>
			<div ref={settingsButtonRef} className={styles.settingsButton} onClick={toggleSettings}/>
			<div className={styles.settingsWrapper}>
				{settings}
			</div>
		</div>
	)
}
