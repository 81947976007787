import React, { useState, useRef, useEffect, useContext } from 'react'
import { APIContext } from '../../utils/api'
import { industryOptions } from '../../utils/industryOptions'
import Sidebar from '../../components/sidebar/Sidebar'
import NewAnalysis from '../../components/dashboard/NewAnalysis'
import LatestAnalysis from '../../components/dashboard/LatestAnalysis'
import Todo from '../../components/dashboard/Todo'
import TopThree from '../../components/dashboard/TopThree'
import Benchmarks from '../../components/dashboard/Benchmarks'
import AnalysisCount from '../../components/dashboard/AnalysisCount'
import SystemStatus from '../../components/dashboard/SystemStatus'
import Guides from '../../components/dashboard/Guides'
import News from '../../components/dashboard/News'
import styles from './styles.module.css'

// component function
export default function Dashboard() {

	const context = useContext(APIContext)
	const [analyses, setAnalyses] = useState()
	const [analysesFiltered, setAnalysesFiltered] = useState()
	const industryFilterRef = useRef()
	const yearFilterRef = useRef()
	const mediaTypeFilterRef = useRef()

	// get user’s analysis list
	useEffect(() => {
		context.io.socket.get('/api/v1/video', (data, res) => {
			if (res.statusCode === 200) {
				setAnalyses(data)
				setAnalysesFiltered(data)
				industryFilterRef.current = document.getElementById('industryFilter')
				yearFilterRef.current = document.getElementById('yearFilter')
				mediaTypeFilterRef.current = document.getElementById('mediaTypeFilter')
				adjustSelectWidth(industryFilterRef.current)
				adjustSelectWidth(yearFilterRef.current)
				adjustSelectWidth(mediaTypeFilterRef.current)
			} else {
				// TODO: error handling
			}
		})
	}, [context.io.socket])

	// filter top 3 on selected industry and media type
	function filterOnIndustryYearMediaType() {
		const iSel= industryFilterRef.current
		const ySel= yearFilterRef.current
		const mSel = mediaTypeFilterRef.current
		const industries = iSel.value ? analyses.filter(o => o.industryCode === parseInt(iSel.value)) : analyses
		const industriesYears = ySel.value ? industries.filter(o => new Date(o.createdAt).getFullYear() === parseInt(ySel.value)) : industries
		const industriesYearsTypes = mSel.value ? industriesYears.filter(o => o.analysisType === parseInt(mSel.value)) : industriesYears
		setAnalysesFiltered(industriesYearsTypes)
		adjustSelectWidth(iSel)
		adjustSelectWidth(ySel)
		adjustSelectWidth(mSel)
	}

	// adapt select menu width to fit selected text
	function adjustSelectWidth(sel) {
		const measure = document.getElementById('measureWidth')
		measure.innerHTML = sel.options[sel.selectedIndex].text
		sel.style.width = measure.offsetWidth + 25.5 + 'px'
	}
	
	// find and sort industry codes and analysis types in FINISHED analyses
	const usedIndustryCodes = [...new Set(analyses?.filter(a=> a.frontendState?.status === 'finished').map(a => a.industryCode).sort((a, b) => (a - b)))]
	const usedYears = [...new Set(analyses?.filter(a=> a.frontendState?.status === 'finished').map(a => new Date(a.createdAt).getFullYear()).sort((a, b) => (b - a)))] // years desc
	const usedAnalysisTypes = [...new Set(analyses?.filter(a=> a.frontendState?.status === 'finished').map(a => a.analysisType).sort((a, b) => (a - b)))]

	// TODO: get industries and analysis types dynamically from backend
	const analysisTypes = [
		{ value: 1, label: 'Video' },
		{ value: 2, label: 'Animatic' },
		{ value: 3, label: 'Still image' }
	]

 	// PLCEHOLDER DATA //TODO: get real benchmark scores (filtered by Industry, year and analysis type)
 	const benchmarksFiltered = {
		clarity: {
			my: 65,
			ind: 57,
			comp: null
		},
		brand: {
			my: 58,
			ind: 53,
			comp: null
		},
		comms: {
			my: 37,
			ind: 42,
			comp: null
		},
	}

	return (
		<>
			<Sidebar />
			<div className={styles.wrapper}>
				<h2>Dashboard</h2>
				<h3>Analyses</h3>
				<div className={styles.cardGroup}>
					<NewAnalysis />
					<LatestAnalysis analyses={analyses}/>
					<Todo analyses={analyses}/>
				</div>
				<div className={styles.headingGroup}>
					<div className={styles.industryYearFilter}>
						{analyses && (
							<div className={styles.industryYearWrapper}>
								<div className={styles.selectWrapper}>
									<select id="industryFilter" ref={industryFilterRef} className={styles.select} title="Filter on industry" onChange={filterOnIndustryYearMediaType}>
										<option value=''>All industries</option>
										{industryOptions.filter(io => usedIndustryCodes.indexOf(io.value) > -1).map((o,i) => {
											return(<option key={'industry-'+i} value={o.value}>{o.label}</option>)
										})}
									</select>
								</div>
								<div className={styles.selectWrapper}>
									<select id="yearFilter" ref={yearFilterRef} className={styles.select} title="Filter on year" onChange={filterOnIndustryYearMediaType}>
										<option value=''>All years</option>
										{usedYears.map((i) => {
											return(<option key={'year-'+i} value={i}>{i}</option>)
										})}
									</select>
								</div>
							</div>
						)}
					</div>
					<h3>Analysis insights</h3>
					<div className={styles.mediaTypeFilter}>
						{analyses && (
							<div className={styles.selectWrapper}>
								<select id="mediaTypeFilter" ref={mediaTypeFilterRef} className={styles.select} title="Filter on media type" onChange={filterOnIndustryYearMediaType}>
									<option value=''>All media types</option>
									{analysisTypes.filter(at => usedAnalysisTypes.indexOf(at.value) > -1).map((o,i) => {
										return(<option key={'analysistype-'+i} value={o.value}>{o.label}</option>)
									})}
								</select>
							</div>
						)}
					</div>
					<div id='measureWidth' className={styles.selectWidth} />
				</div>
				<div className={styles.cardGroup}>
					<TopThree analyses={analysesFiltered} type='clarity' />
					<TopThree analyses={analysesFiltered} type='brand' />
					<TopThree analyses={analysesFiltered} type='comms' />
				</div>
				<div className={styles.cardGroup}>
					<Benchmarks benchmarks={benchmarksFiltered} />
					<AnalysisCount analyses={analysesFiltered} />
				</div>
				<h3>Knowledge base</h3>
				<div className={styles.cardGroup}>
					<SystemStatus status={1} />
					<Guides />
					<News />
				</div>
			</div>
		</>
	)
}
