import React from 'react'
import styles from './styles.module.css'

// component function
export default function AnalysisCount(props) {

	const { analyses } = props
	const finishedAnalyses = analyses?.filter(a => a.frontendState?.status === 'finished')
	const year = ['J','F','M','A','M','J','J','A','S','O','N','D']
	const maxBarPct = 60

	// find month w most analyses (to set max height)
	let maxAnalysisCount = 0
	for (let i=0; i<12; i++) {
		const countInMonth = finishedAnalyses?.filter(o => new Date(o.createdAt).getMonth() === i).length
		maxAnalysisCount = countInMonth > maxAnalysisCount ? countInMonth : maxAnalysisCount
	}

	return (
		<div className={styles.container}>
			<div className={styles.heading}>Analysis count</div>
			<div className={styles.graphContainer}>
				{year.map((month,i) => {
					const count = finishedAnalyses?.filter(o => new Date(o.createdAt).getMonth() === i).length
					return(
						<div key={'graphMonth-'+i} className={styles.graphMonth}>
							<div className={styles.count}>{count}</div>
							<div className={styles.graphBar} style={{height: (maxAnalysisCount === 0 ? '1px' : Math.round(count / maxAnalysisCount * maxBarPct) + '%')}} />
							<div className={styles.month}>{month}</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
