import React, { useState, useEffect } from 'react'
import DropZone from './DropZone'
import styles from './styles.module.css'

// component function
export default function VideoOrImage(props) {

	const { setMediaType, setMediaFile, setMediaRatio } = props

	const [file, setFile] = useState()
	const [mediaFormat, setMediaFormat] = useState('')
	const [mediaDimensions, setMediaDimensions] = useState('')

	const videoAspectRatios = [
		{ ratio: 0.56, label: '9:16' },		// i.e. 720x1280 / 1080x1920 / 2160x3840 (UHD)
		{ ratio: 0.75, label: '3:4' },		// i.e. 480x640 / 960x1280
		{ ratio: 0.8, label: '4:5' },		// i.e. 1080x1350 / Instagram
		{ ratio: 1, label: '1:1' },			// i.e. 1080x1080 / square / div. social
		{ ratio: 1.25, label: '5:4' },		// i.e. 1350x1080 / Instagram
		{ ratio: 1.33, label: '4:3' },		// i.e. 640x480 / 1280x960
		{ ratio: 1.77, label: '16:9' },		// i.e. 1280x720 / 1920x1080 / 3840x2160 (UHD)
		{ ratio: 1.9, label: '1.90:1' },	// i.e. 4096x2160 / 4K
		{ ratio: 2.37, label: '21:9' }		// i.e. 2560x1080 / ultra widescreen / cinematic
	]

	const ratioMargin = .022 // allowed deviation from defined ratio values

	// get image/video width/height
	useEffect(() => {
		setMediaFormat('')
		if (file?.type.match('image.*')) {
			const img = new Image()
			img.src = window.URL.createObjectURL(file)
			img.onload = () => {
				img.width && setMediaFormat('Image')
				img.width && setMediaDimensions('(' + img.width + ' x ' + img.height + ' px)')
				img.width && setMediaType('image') // inform parent
				img.width && setMediaFile(file) // inform parent
				img.width && setMediaRatio(img.width/img.height) // inform parent
			}
		} else if (file?.type.match('video.*')) {
			const vid = document.createElement('video')
			vid.src = window.URL.createObjectURL(file)
			vid.addEventListener("loadedmetadata", function removeVideo() {
				vid.removeEventListener("loadedmetadata", removeVideo)
				vid.videoWidth && setMediaFormat(getAspectRatioLabel(vid.videoWidth/vid.videoHeight) + ' Video')
				vid.videoWidth && setMediaDimensions('(' + vid.videoWidth + ' x ' + vid.videoHeight + ' px)')
				vid.videoWidth && setMediaType('video') // inform parent
				vid.videoWidth && setMediaFile(file) // inform parent
				vid.videoWidth && setMediaRatio(vid.videoWidth/vid.videoHeight) // inform parent
				vid.src = ''
				vid.load()
			})
		} else { // error
			setMediaFile(file) // inform parent
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file])

	// get vide aspect ratio label
	function getAspectRatioLabel(ratio) {
		let closest = videoAspectRatios[0]
		let closestDiff = Math.abs(ratio - closest.ratio)
		for (let i=1; i<videoAspectRatios.length; i++) {
			let current = videoAspectRatios[i]
			let currentDiff = Math.abs(ratio - current.ratio)
			if (currentDiff < closestDiff) {
				closest = current
				closestDiff = currentDiff
			}
		}
		return closestDiff < ratioMargin ? closest.label : 'Other'
	}

	const formatTexts = mediaFormat && (
		<div className={styles.formatContainer}>
			<div>Format:</div>
			<div className={styles.format}>{mediaFormat}</div>
			<div>{mediaDimensions}</div>
		</div>
	)

	return (
		<>
			<div className={styles.sectionHeading}>1. Video or image</div>
			<div className={styles.container}>
				<DropZone fileAdded={(file) => setFile(file)} />
				{formatTexts}
			</div>
		</>
	)
}
