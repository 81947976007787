import React, { useState, useRef, useEffect } from 'react'
import { colors } from '../../themes/colors'

// styles
const uploadContainerStyle = {
	display:'flex',
	marginTop:'auto',
	alignItems: 'center'
}

const uploadTextStyle = {
	marginLeft:'26px',
	width:'220px',
	fontSize:'14px',
	lineHeight:'20px'
}

// component function
export default function DropZoneAvatar(props) {

	const {avatar, setAvatarImage} = props

	const [highlight, setHighlight] = useState()
	const [file, setFile] = useState()
	const [error, setError] = useState()
	const fileInputRef = useRef()
	const canvasRef = useRef()
	const ctxRef = useRef()

	// dynamic styles
	const dropZoneStyle = {
		display: 'flex',
		position: 'relative',
		opacity: (file || avatar) && !highlight ? '0' : '1',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		width: '120px',
		height: '120px',
		border: '2px solid',
		borderRadius: '50%',
		borderStyle: 'dashed',
		borderColor: colors.dropZoneBorder,
		backgroundColor: highlight && colors.dropZoneAvatarHighlight,
		fontSize: '14px',
		lineHeight: '20px',
		color: colors.text,
		padding: '19px',
		boxSizing: 'border-box',
		cursor: 'pointer',
		marginTop: '-127px'
	}

	const logoCanvasStyle = {
		opacity: (file || avatar) && !highlight ? '1' : '0',
		borderRadius:'50%',
	}

	const statusStyle = {
		pointerEvents:'none',
		color: error && !highlight && colors.error
	}

	// context- & canvas refs inits on mount
	useEffect(() => {
		ctxRef.current = canvasRef.current.getContext('2d')
		ctxRef.current.imageSmoothingEnabled = true
		ctxRef.current.imageSmoothingQuality = 'high'
		if (avatar) { // load existing avatar into canvas
			const img = new Image()
			img.crossOrigin = 'anonymous'
			img.onload = () => setCanvasImage(img)
			img.src = avatar
		}
 	},[avatar])

	// open file select dialogue
	function openFileDialog() {
		fileInputRef.current.click()
	}

	// validate file when added
	function onFileAdded(e) {
		validateFile(e.target.files[0])
	}

	// handle dropped file
	function onDrop(e) {
		e.preventDefault()
		setHighlight(false)
		validateFile(e.dataTransfer.files[0])
	}

	// handle drag over
	function onDragOver(e) {
		e.preventDefault()
		setHighlight(true)
	}

	// handle drag leave
	function onDragLeave() {
		setHighlight(false)
	}

	// paint avatar image to canvas (cover mode)
	function setCanvasImage(img) {
		let ox = 0
		let oy = 0
		let ow = img.width
		let oh = img.height
		if (ow/oh > 1) { // wider
			ox = (ow-oh)/2
			ow = oh
		} else if (ow/oh < 1) { // taller
			oy = (oh-ow)/2
			oh = ow
		}
		ctxRef.current.clearRect(0,0,120,120)
		ctxRef.current.drawImage(img,ox,oy,ow,oh,0,0,120,120)
	}

	// validate file
	function validateFile(f) {
		let err = null
		err = f.size > 1000000 ? 'Image must be smaller than 1 MB' : err
		err = f.type !== 'image/jpeg' && f.type !== 'image/gif' && f.type !== 'image/png' && f.type !== 'image/svg+xml' ? 'Please only upload image files' : err
		setError(err)
		if (!err) {
			setFile(f)
			const img = new Image()
			img.onload = () => {
				setCanvasImage(img)
				setAvatarImage(canvasRef.current.toDataURL('image/png')) // set cropped image as avatar image to save
			}
			const reader = new FileReader()
			reader.onload = (e) => {
				if (e.target.readyState === FileReader.DONE) {
					img.src = e.target.result
				}
			}
			reader.readAsDataURL(f)
		}
	}

	const statusText = highlight ? 'Drop logo right here' : error ? error : 'Drag logo or click here'

	return (
		<>
			<div style={uploadContainerStyle}>
				<div>
					<canvas
						style={logoCanvasStyle}
						width={120}
						height={120}
						ref={canvasRef}
					/>
					<div
						style={dropZoneStyle}
						onDragOver={onDragOver}
						onDragLeave={onDragLeave}
			    		onDrop={onDrop}
			    		onClick={openFileDialog}>
						<input
							type="file"
							ref={fileInputRef}
							style={{display:'none'}}
							onChange={onFileAdded}
							accept=".jpg, .jpeg, .gif, .png, .svg"
						/>
						<div style={statusStyle}>{statusText}</div>
					</div>
				</div>
				<div style={uploadTextStyle}>Upload your company logo/icon (square format) to be displayed in the top bar and in shared reports.</div>
			</div>
		</>
	)
}
