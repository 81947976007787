import React from 'react'
import styles from './styles.module.css'

// component function
export default function CheckBox(props) {

	const { checked } = props

	return (
		<div className={styles.back + (checked ? ' ' + styles.checked : '')} />
	)
}
