import React from 'react'
import { colors } from '../../themes/colors'

// component function
export default function AnalysisScore(props) {

	const { marg=0, dia=80, stw=11, fsize=18, lsize='inherit', type, score } = props
	const limit1 = 20
	const limit2 = 40

	// dynamic styles
	const scoreWrapperStyle = {
		margin: marg,
		position: 'relative'
	}

	const scoreStyle = {
		width: dia + 'px',
		height: dia + 'px',
	}

	const circleBgStyle = {
		fill: 'none',
		stroke: colors.progressbarBack,
		strokeWidth: stw
	}

	const scoreTextStyle = {
		fill: colors.text,
		fontSize: fsize + 'px',
		fontFamily: 'Greycliff demibold',
		textAnchor: 'middle'
		}

	const labelTextStyle = {
		fontSize: lsize + 'px',
		lineHeight: '18px',
		color: colors.text
	}

	const circleStyle = {
		fill: 'none',
		strokeWidth: stw,
		strokeLinecap: 'round',
		//stroke: Math.round(score) < limit1 ? theme.analysisScoreLowColor : Math.round(score) < limit2 ? .analysisScoreMediumColor : theme.analysisScoreHighColor,
		stroke: type?.toLowerCase().indexOf("clarity") > -1 ? colors.clarity : type?.toLowerCase().indexOf("brand") > -1 ? colors.brand : colors.comms, // TODO: NEW METRIC TYPES/SCORES
		transformOrigin: 'center',
		transform: 'rotate(4.75deg)',
		display: (score === 0) ? 'none' : 'block'
	}

	const tickStyle = {
		position: 'absolute',
		top: dia/2,
		left: '50%',
		width: dia/2-1,
		height: '1px',
		borderRight: stw-2 + 'px solid ' + colors.progressbarTick,
		boxSizing: 'border-box',
		transformOrigin: 'left',
		transform: 'rotate('+(360*(limit1/100)-90)+'deg)'
	}

	const circleValues = "M"+(dia/2)+" "+(stw/2)+" a "+((dia-stw)/2)+" "+((dia-stw)/2)+" 0 0 1 0 "+(dia-stw)+" a "+((dia-stw)/2)+" "+((dia-stw)/2)+" 0 0 1 0 -"+(dia-stw)
	const strokeLength  = Math.floor(score/100 * Math.PI * (dia-stw)) - stw/2
	const dashValue  = strokeLength  < 0 ? 0 : strokeLength
	const value = score ? Math.round(score) : 0
	const dashStyle  = { ...circleStyle, opacity: score ? 1 : 0 }

	const label = type ? type.split('\n').map((item, i) => {	// line break hack
    	return <p key={i}>{item}</p>
	}) : null

	return (
		<div>
			<div style={scoreWrapperStyle}>
				<svg style={scoreStyle}>
					<path style={circleBgStyle} d={circleValues} />
					<path style={dashStyle} strokeDasharray={dashValue+',1000'} d={circleValues} />
					<text style={scoreTextStyle} x={dia/2} y={dia/2+fsize/3}>{value}</text>
				</svg>
				<div style={tickStyle} />
				<div style={{...tickStyle, transform:'rotate('+(360*(limit2/100)-90)+'deg)'}} />
			</div>
			<div style={labelTextStyle}>{label}</div>
		</div>
	)
}
