import React from 'react'
import styles from './styles.module.css'

// component function
export default function AverageSlide(props) {

	const { scores, type } = props

	return (
		<div id={'slide-' + (type === 'comms' ? 0 : type === 'clarity' ? 1 : 2)} className={styles.averagesContainer + ' ' + styles[type]}>
			<div className={styles.averageContainer}>
				<div className={styles.scoreBox + ' ' + (!scores.my && styles.na)}>{scores.my ? scores.my : 'n/a'}</div>
				<div className={styles.scoreLabel}>My average</div>
			</div>
			<div className={styles.averageContainer}>
				<div className={styles.scoreBox + ' ' + (!scores.ind && styles.na)}>{scores.ind ? scores.ind : 'n/a'}</div>
				<div className={styles.scoreLabel}>Industry average</div>
			</div>
			<div className={styles.averageContainer}>
				<div className={styles.scoreBox + ' ' + (!scores.comp && styles.na)}>{scores.comp ? scores.comp : 'n/a'}</div>
				<div className={styles.scoreLabel}>Competitor avg.</div>
			</div>
		</div>
	)
}
