import React, { useState, useEffect, useRef, useContext } from 'react'
import { GlobalContext } from '../../utils/globalState'
import { colors } from '../../themes/colors'
import AnalysisCueVideoObjectEasy from './AnalysisCueVideoObjectEasy'
import arrowLeft from '../../assets/images/easy-tag-arrow-left.svg'
import arrowRight from '../../assets/images/easy-tag-arrow-right.svg'

// styles
const btnContainerStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '600px',
	margin: '30px auto'
}

// component function
export default function AnalysisCueVideoEasy(props) {

	const { chunks, video, origSegments, maxWidth, forceUpdate, objRef1, objRef2, objRef3, objRef4, objRef5 } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [currentFrame, setCurrentFrame] = useState()	// eslint-disable-line no-unused-vars
	const [segmentIndex, setSegmentIndex] = useState()	// eslint-disable-line no-unused-vars
	const maxWidthRef = useRef(maxWidth)
	const canvasRef = useRef()
	const ctxRef = useRef()
	const canvasWidthRef = useRef()
	const canvasHeightRef = useRef()
	const scaleRef = useRef()
	const frameRef = useRef(0)
	const sceneIndexRef = useRef(0)
	const segmentIndexRef = useRef(0)
	const cueTypeCategory = globalState.cueTypes.find(cueType => cueType.id === getSegmentsInCurrentScene()[segmentIndexRef.current]?.cueType)?.cueTypeCategory
	const nameMaxChars = 28

	let spacePressed = false

	// calculate video canvas- & css sizes
	const processedWidth = video.width * video.processing_scale
	const processedHeight = video.height * video.processing_scale * (1/video.streamInfo.pixelAspectRatio)
	let canvasWidth = 640 * window.devicePixelRatio
	let canvasHeight = 360 * window.devicePixelRatio
	let canvasWidthCss = maxWidth
	let canvasHeightCss = maxWidth/640*360
	if (processedHeight/processedWidth > 360/640) {
		canvasWidthCss = Math.ceil(processedWidth/processedHeight * canvasHeightCss)
	}
	canvasWidth = canvasWidthCss * window.devicePixelRatio
	canvasHeight = canvasHeightCss * window.devicePixelRatio

	const w = chunks[0].width/5
	const h = chunks[0].height/6

	// set refs (for js functions that don’t get state)
	maxWidthRef.current = maxWidth
	canvasWidthRef.current = canvasWidth
	canvasHeightRef.current = canvasHeight
	scaleRef.current = canvasWidth/canvasWidthCss

	// dynamic styles
	const canvasStyle = {
		width: canvasWidthCss,
		height: canvasHeightCss,
		display: 'block',
		margin: '0 auto',
		boxShadow: '0 0 0 1px ' + colors.videoShadow
	}
	
	const buttonStyle = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '42px',
		paddingTop: '1px',
		fontFamily: 'Greycliff demibold',
		fontSize: '14px',
		textTransform: 'uppercase',
		letterSpacing: '.3px',
		borderRadius: '30px',
		cursor: 'pointer',
		boxSizing: 'border-box'
	}

	const arrowLeftStyle = {
		...buttonStyle,
		width: '160px',
		paddingLeft: '28px',
		backgroundImage: 'url(' + arrowLeft + ')',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '9% 9px',
		opacity: sceneIndexRef.current === 0 && segmentIndexRef.current === 0 ? .5 : 1,
		cursor: sceneIndexRef.current === 0 && segmentIndexRef.current === 0 ? 'default' : 'pointer'
	}

	const arrowRightStyle = {
		...buttonStyle,
		width: '160px',
		paddingRight: '26px',
		backgroundImage: 'url(' + arrowRight + ')',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '91% 9px',
		opacity: sceneIndexRef.current === video.scenes.length-1 && segmentIndexRef.current === getSegmentsInCurrentScene().length-1 ? .5 : 1,
		cursor: sceneIndexRef.current === video.scenes.length-1 && segmentIndexRef.current === getSegmentsInCurrentScene().length-1 ? 'default' : 'pointer'
	}

	const tagBtnStyle = {
		...buttonStyle,
		width: '220px',
		color: cueTypeCategory === 1 || cueTypeCategory === 2 ? colors.textInverse : colors.text,
		backgroundColor: cueTypeCategory === 1 ? colors.brand : cueTypeCategory === 2 ? colors.comms : colors.background2
	}

	// context- & canvas refs setup on mount
	useEffect(() => {
		// canvas setup
		ctxRef.current = canvasRef.current.getContext('2d')
		ctxRef.current.imageSmoothingEnabled = true
	},[]) 

	// keyboard listeners on origsegment update (to keep data on keyboard vs mouse click events in sync)
	useEffect(() => {
		// space bar video control
		window.addEventListener('keydown', keyPressed)
		window.addEventListener('keyup', keyReleased)
		// cleanup on unmount
		return () => {
			window.removeEventListener('keydown', keyPressed)
			window.removeEventListener('keyup', keyReleased)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[origSegments]) 

	// render video frame on all component updates
	useEffect(() => {
		renderFrame(frameRef.current)
 	})

	// handle key pressed events
	function keyPressed(e) {
		if (spacePressed) {
			e.preventDefault()
		} else if (e.keyCode === 32) { // spacebar
			e.preventDefault()
			spacePressed = true // flag to prevent repeated spaces
			toggleSegment()
		} else if (e.keyCode === 37) { // left arrow key
			goPrevSegment()
		} else if (e.keyCode === 39) { // right arrow key
			goNextSegment()
		}
	}

	// handle key released events
	function keyReleased(e) {
		if (e.keyCode === 32) { // space
			spacePressed = false
		}
	}

	// handle left/right arrow click
	function arrowClicked(dir) {
		dir === 'left' ? goPrevSegment() : goNextSegment()
	}

	// get segments in current scene
	function getSegmentsInCurrentScene() {
		if (!video.scenes.length || video.scenes[0]?.data?.dummy) { // if no scenes - create dummy-scene and return all segments (sorted by first object startframe) TODO: enable scene detection
			if (!video.scenes[0]?.data?.dummy) {
				video.scenes = [{frameNo:0, data:{dummy:true}}]
			}
			video.segments[0].firstObjectFrame === undefined && video.segments.map(seg => seg.firstObjectFrame = video.objects.filter(o => o.segment === seg.id)[0].frameNo)
			return video.segments.sort((a,b) => a.firstObjectFrame - b.firstObjectFrame)
		} else {
			const currentSceneStartFrame = video.scenes[sceneIndexRef.current].frameNo
			const currentSceneEndFrame = sceneIndexRef.current === video.scenes.length-1 ? video.frameCount-1 : video.scenes[sceneIndexRef.current+1].frameNo-1
			const sceneObjects = video.objects.filter(o => o.frameNo >= currentSceneStartFrame && o.frameNo <= currentSceneEndFrame)
			const sceneSegmentIds = [...new Set(sceneObjects.map(obj => obj.segment))]
			return video.segments.filter(s => sceneSegmentIds.indexOf(s.id) > -1).sort((a,b) => a.frameNo - b.frameNo)
		}
	}

	// go to previous segment
	function goPrevSegment() {
		const currentSceneSegments = getSegmentsInCurrentScene()
		if (segmentIndexRef.current > 0) {
			segmentIndexRef.current --
			const currentSceneStartFrame = video.scenes[sceneIndexRef.current].frameNo
			frameRef.current = video.objects.filter(o => o.segment === currentSceneSegments[segmentIndexRef.current].id && o.frameNo >= currentSceneStartFrame)[0].frameNo
			setSegmentIndex(segmentIndexRef.current)
			setCurrentFrame(frameRef.current)
		} else if (sceneIndexRef.current > 0) { // previous scene
			sceneIndexRef.current --
			segmentIndexRef.current = getSegmentsInCurrentScene().length
			goPrevSegment()
		}
	}

	// go to next segment
	function goNextSegment() {
		const currentSceneSegments = getSegmentsInCurrentScene()
		if (segmentIndexRef.current < currentSceneSegments.length-1) {
			segmentIndexRef.current ++
			const currentSceneStartFrame = video.scenes[sceneIndexRef.current].frameNo
			frameRef.current = video.objects.filter(o => o.segment === currentSceneSegments[segmentIndexRef.current].id && o.frameNo >= currentSceneStartFrame)[0].frameNo
			setSegmentIndex(segmentIndexRef.current)
			setCurrentFrame(frameRef.current)
		} else if (sceneIndexRef.current < video.scenes.length-1) { // next scene
			sceneIndexRef.current ++
			segmentIndexRef.current = -1
			goNextSegment()
		}
	}

	// set selected cueType on segment and force parent update
	function toggleSegment() {
		const seg = getSegmentsInCurrentScene()[segmentIndexRef.current]
		let origSeg = origSegments.find(s => s.id === seg.id)
		if (typeof origSeg !== 'object' || origSeg === null) {
			origSeg = seg 
		}
		seg.cueType = seg.cueType !== null ? null : origSeg.cueType !== null ? origSeg.cueType : globalState.cueTypes.find(ct => ct.defaultForCreationMethod === seg.creationMethod).id // if not selected, select object and set cueType based on default for the segment’s creationMethod
		seg.dirty = seg.id < 0 ? false : seg.cueType === origSeg.cueType && seg.name.slice(0,nameMaxChars) === origSeg.name.slice(0,nameMaxChars) ? false : true // set 'dirty' on segment depending on diff from original value
		forceUpdate()
	}

	// cut frame image from chunk and paint it on canvas
	function renderFrame(rframe) {
		const ctx = ctxRef.current
		const chunk = Math.floor(rframe/30)
		const col = rframe % 5
		const row = Math.floor(rframe % 30 / 5)
		ctx.drawImage(chunks[chunk], w*col, h*row, w, h, 0, 0, canvasWidthRef.current, canvasHeightRef.current)
	}

	const segment = getSegmentsInCurrentScene()[segmentIndexRef.current]
	const currentSceneStartFrame = video.scenes[sceneIndexRef.current].frameNo
	const segmentObjects = video.objects.filter(o => o.segment === segment.id && o.frameNo >= currentSceneStartFrame)
	const objectsInFirstSegmentFrame = segmentObjects.filter(o => o.frameNo === segmentObjects[0].frameNo)
	const origSegment = origSegments.find(s => s.id === segment.id)
	const tagButton = <div ref={objRef4} style={tagBtnStyle} onClick={toggleSegment}>{cueTypeCategory ? 'Deselect object' : 'Select object'}</div>

	// edit object
	const editObject = (objectsInFirstSegmentFrame.length > 0 &&
		<AnalysisCueVideoObjectEasy
			objects={objectsInFirstSegmentFrame}
			segment={segment}
			origSegment={origSegment}
			width={canvasWidthCss}
			height={canvasHeightCss}
			forceUpdate={forceUpdate}
			toggleSegment={toggleSegment}
			nameMaxChars={nameMaxChars}
			objRef1={objRef1}
			objRef2={objRef2}
		/>
	)

	return (
		<div style={{position:'relative'}}>
			<canvas id="canvas" style={canvasStyle}
				ref={canvasRef}
				width={canvasWidth}
				height={canvasHeight}
			/>
			{editObject}
			<div style={btnContainerStyle}>
				<div ref={objRef3} style={arrowLeftStyle} onClick={e=>arrowClicked('left')}>Prev. object</div>
				{tagButton}
				<div ref={objRef5} style={arrowRightStyle} onClick={e=>arrowClicked('right')}>Next object</div>
			</div>
		</div>
	)
}
