import React, { useContext, useState, useRef, useEffect } from 'react'
import { Prompt } from 'react-router'
import Alert from '../common/Alert'
import DropZoneAvatar from './DropZoneAvatar'
import { APIContext } from '../../utils/api'
import { GlobalContext } from '../../utils/globalState'
import { colors } from '../../themes/colors'
import selectArrow from '../../assets/images/select-menu-arrow.svg'

// styles
const billingBoxLeftStyle = {
	display: 'flex',
	flexDirection: 'column',
	flexBasis: '290px',
	flexGrow: '335',
	paddingRight: '70px',
	boxSizing: 'border-box',
	textAlign: 'left'
}

const billingBoxRightStyle = {
	flexBasis: '290px',
	flexGrow: '200',
	margin: '30px 0 0'
}

const accountElementBack = {
	padding: '53px 50px 70px',
	borderTop: '1px solid ' + colors.borderLight,
	minWidth: '980px'
}

const billingBoxStyle = {
	display: 'flex',
	flexWrap: 'wrap',
	backgroundColor: colors.background2,
	margin: '27px 0 40px',
	padding: '0 30px 30px',
	color: colors.text
}

// component function
export default function AccountBilling(props) {

	const {org} = props
	const storeData = org?.companyStoreData
	const customer = storeData?.customer
	const addr = customer?.address
	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const [alertTask, setAlertTask] = useState()
	const [countries, setCountries] = useState()
	const [submitting, setSubmitting] = useState(false)
	const alertObjectRef = useRef(null)

	// form data
	const [billingEmail, setBillingEmail] = useState(org.billingEmail ? org.billingEmail : '')
	const [orgName, setOrgName] = useState(org.name ? org.name : '')
	const [address, setAddress] = useState(addr ? addr.line1 : '')
	const [city, setCity] = useState(addr ? addr.city : '')
	const [zip, setZip] = useState(addr ? addr.postal_code : '')
	const [state, setState] = useState(addr ? addr.state : '')
	const [country, setCountry] = useState(addr ? addr.country : '')
	const [taxType, setTaxType] = useState(storeData?.customer_tax_ids?.length > 0 ? storeData.customer_tax_ids[0].type : '')
	const [taxId, setTaxId] = useState(storeData?.customer_tax_ids?.length > 0 ? storeData.customer_tax_ids[0].value : '')
	const [avatar, setAvatar] = useState(org.settings.avatar ? org.settings.avatar : '') // eslint-disable-line no-unused-vars
	const [avatarImage, setAvatarImage] = useState()

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// populate country menu
	const countryMenu = (countries &&
		<select id='company-country' className={'fs-input-select-signup' + (country ? ' force-white' : '')} placeholder="Country *" style={{background: colors.background0 + ' url(' + selectArrow + ') no-repeat 95% 50%'}} value={country} onChange={e=>countrySelected(e.target.value)}>
			<option value="">Country *</option>
			{countries.filter((v,i,a)=>a.findIndex(t=>(t.countryCodeIso3166_2===v.countryCodeIso3166_2))===i).map((coun,i)=><option key={i} value={coun.countryCodeIso3166_2}>{coun.country}</option>) /* unique countries by country code */ }
		</select>
	)

	// populate tax type menu
	const taxTypes = countries && country ? countries.filter(coun => coun.countryCodeIso3166_2 === country) : []
	const taxTypeMenu = (taxTypes?.length > 1 &&
		<select id='company-taxtype' className={'fs-input-select-signup' + (taxType ? ' force-white' : '')} placeholder="Tax type *" style={{background: colors.background0 + ' url(' + selectArrow + ') no-repeat 95% 50%'}} value={taxType} onChange={e=>taxTypeSelected(e.target.value)}>
			<option value="">Tax type *</option>
			{taxTypes.map((type,i)=><option key={i} value={type.taxIdType}>{type.description}</option>)}
		</select>
	)

	// set tax id placeholder
	const example = taxTypes?.length > 0 && taxType ? 'Tax ID * (e.g. ' + taxTypes.find(type => type.taxIdType === taxType).example + ')': 'Tax ID *'

	// check if form values have changes compared to stored data
	const origValuesRef = useRef(JSON.stringify(valuesObject()))
	const canSubmit = origValuesRef.current !== JSON.stringify(valuesObject()) || avatarImage !== undefined

	// get country/tax list
	useEffect(() => {
		context.io.socket.get('/api/v1/subscription/config', (data, res) => {
			if (res.statusCode === 200) {
				setCountries(data.countryTaxType)
			} else {
				showAlert('Error getting country list: ' + res.error.message)
			}
		})
	}, [context.io.socket])

	// country selected
	function countrySelected(countryCode) {
		setCountry(countryCode)
		const countryCodes = countries.filter(coun => coun.countryCodeIso3166_2 === countryCode)
		taxTypeSelected(countryCodes.length === 1 ? countryCodes[0].taxIdType : '') // set taxType value directly if only one or reset tax type menu
	}

	// tax type selected
	function taxTypeSelected(taxType) {
		setTaxType(taxType)
		setTaxId('')
	}

	// return an object with form field values
	function valuesObject() {
		return {
			orgBillingEmail: billingEmail,
			orgName: orgName,
			orgLine1: address,
			orgCity: city,
			orgPostalCode: zip,
			orgState: state,
			orgCountry: country,
			orgTaxIdType: taxType ? taxType : taxTypes?.length === 0  && country ? countries.find(coun => coun.countryCodeIso3166_2 === country).taxIdType : '',
			orgTaxId: taxId,
			avatarImage: avatarImage ? avatarImage : ''
		}
	}

	// validate email
	function validateEmail(email) {
		const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i //eslint-disable-line
		return re.test(String(email).toLowerCase())
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// save billing info
	function saveBillingInfo() {
		if (submitting) return
		const req = valuesObject()
		if (req.orgBillingEmail.trim().length < 1) {
			showAlert('Please enter your billing email')
		} else if (!validateEmail(req.orgBillingEmail)) {
			showAlert('Error in billing email address')
		} else if (req.orgLine1.trim().length < 1) {
			showAlert('Please fill in your billing address')
		} else if (req.orgCity.trim().length < 1) {
			showAlert('Please fill in your city')
		} else if (req.orgPostalCode.trim().length < 1) {
			showAlert('Please fill in your zip/postal code')
		} else if (req.orgState.trim().length < 1) {
			showAlert('Please fill in your state/region')
		} else if (!req.orgCountry) {
			showAlert('Please select your country')
		} else if (!req.orgTaxIdType) {
			showAlert('Please select tax type')
		} else if (!req.orgTaxId) {
			showAlert('Please enter your company tax ID')
		} else {
			setSubmitting(true)
			context.io.socket.patch('/api/v1/organization', req, (data, res) => { // billing info update request
				if (res.statusCode === 200) { // successful submit
					origValuesRef.current = JSON.stringify(req) // update change-reference object
					setGlobalState({...globalState, userData:{...globalState.userData, organization:data}}) // update organization in globalState
					showAlert('Company info has been updated')
				} else if (res.statusCode === 410) { // tax id format error
					const example = taxTypes?.length > 0 && taxType ? ' It should look something like this: ' + taxTypes.find(type => type.taxIdType === taxType).example : ''
					showAlert('Please check that your Tax ID is correct.' + example)
				} else { // other error
					console.log("ERROR: " + res.statusCode)
					showAlert('Something went wrong. Please try again or contact flowsam support.')
				}
				setSubmitting(false)
			})
		}
	}

	const infoText = (org?.subscriptionPlan?.id > 0 && org?.subscriptionPlan?.id < 9 ?
		'The information needed for us to send your monthly invoice. Please note that you will be invoiced on a monthly basis - even if you are on a yearly subscription.' : // standard subscription
		'The information needed for us to send your invoice.' // custom subscription
	)

	return (
		<div style={accountElementBack}>
			<div className="accountElementContainer">
				<h2 style={{color:colors.text}}>Company info</h2>
				<div style={billingBoxStyle}>
					<div style={billingBoxLeftStyle}>
						<h4 style={{margin: '21px 0 14px', padding:'0'}}>{infoText}</h4>
						<h4>If you have any questions or special needs regarding the billing please don’t hesitate to contact <a style={{color:colors.link,textDecoration:'none'}} href="sales@flowsam.ai" target="_blank" rel="noopener noreferrer">sales@flowsam.ai</a>.</h4>
						<DropZoneAvatar avatar={avatar} setAvatarImage={(base64image)=>setAvatarImage(base64image)} />
					</div>
					<div style={billingBoxRightStyle}>
						<input id='company-email' type="email" className={'fs-input-text-signup'} placeholder="Billing email *" spellCheck="false" autoComplete="off" maxLength="100" value={billingEmail} onChange={e=>setBillingEmail(e.target.value)}/>
						<input id='company-name' type="text" className={'fs-input-text-signup'} placeholder="Company" spellCheck="false" autoComplete="off" maxLength="50" value={orgName} onChange={e=>setOrgName(e.target.value)} />
						<input id='company-address' type="text" className={'fs-input-text-signup'} placeholder="Address *" spellCheck="false" autoComplete="off" maxLength="200" value={address} onChange={e=>setAddress(e.target.value)} />
						<input id='company-city' type="text" className={'fs-input-text-signup'} placeholder="City *" spellCheck="false" autoComplete="off" maxLength="100" value={city} onChange={e=>setCity(e.target.value)} />
						<input id='company-zip' type="text" className={'fs-input-text-signup'} placeholder="Zip/postal code *" spellCheck="false" autoComplete="off" maxLength="20" value={zip} onChange={e=>setZip(e.target.value)} />
						<input id='company-state' type="text" className={'fs-input-text-signup'} placeholder="State/region *" spellCheck="false" autoComplete="off" maxLength="100" value={state} onChange={e=>setState(e.target.value)} />
						{countryMenu}
						{taxTypeMenu}
						<input id='company-tax-id' type="text" className={'fs-input-text-signup'} placeholder={example} spellCheck="false" autoComplete="off" maxLength="50" style={{margin:'0', minWidth:'260px'}} value={taxId} onChange={e=>setTaxId(e.target.value)} />
					</div>
				</div>
				<button tabIndex="-1" disabled={!canSubmit || submitting} className="fs-button accountSubmitBtn" onClick={saveBillingInfo}>{submitting ? 'UPDATING...' : 'UPDATE COMPANY INFO'}</button>
			</div>
			<Prompt when={canSubmit} message="If you leave now your billing info changes will be lost" />
			{alert}
		</div>
	)
}
